import ServiceAPI from "../services";

const service = new ServiceAPI();

export function getWeekdaysOption(setOptionsWeekday) {
  service.getWeekdaysOptions().then(resp => {
    if (resp.data){
      setOptionsWeekday(resp.data.results)
    }
  })
}


export function searchSite(setSearchLoad, setSearchResults, searchValue) {
  if (searchValue){
    setSearchLoad(true);
    service.searchSiteCrawler({'site_name': searchValue}).then(resp => {
      if (resp.data){
        setSearchResults(resp.data.results);
        setSearchLoad(false);
      }
    })
  }
}

export function selectSearchResult(setSearchValue, setTvRange, tvRange, selectSearch) {
  setSearchValue(selectSearch.title);
  setTvRange({...tvRange,
    sourceId: selectSearch.id,
    sourceName: selectSearch.name,
    folderName: selectSearch.folderName,
    type: selectSearch.type,
    serverIp: selectSearch.serverIp,
  })
}

export function setTvRangeSelect(tvRange, selectSearch, setTvRange) {
  if(tvRange.sourceName !== selectSearch.name){
    setTvRange({...tvRange, sourceId: ''})
  }else{
    setTvRange({...tvRange, sourceId: selectSearch.id})
  }
}


export function validateTvRage(tvRange, setErrors, errors) {
  // VALIDATION FORM
  let name = false;
  let sourceName = false;
  let timeList = {};
  let validForm = true;

  let folderName = false;
  let serverIp = false;

  if (!tvRange.name || tvRange.name.length < 2){
    validForm = false;
    name = 'Значение должно быть больше 2!'
  }

  if (!tvRange.sourceName || tvRange.sourceName.length < 2){
    validForm = false;
    sourceName = 'Значение должно быть больше 2!'
  }

  if (!tvRange.folderName || tvRange.folderName.length < 2){
    validForm = false;
    folderName = 'Значение должно быть больше 2!'
  }
  if (!tvRange.serverIp || tvRange.serverIp.length < 2){
    validForm = false;
    serverIp = 'Значение должно быть больше 2!'
  }
  if (tvRange.timeList.length > 0){

    tvRange.timeList.forEach((item)=>{
      timeList[item.id] = {
        error: false,
        weekdays: false,
        Time: false,
      };

      if(item.weekdays === '-'){
        validForm = false;
        timeList[item.id].weekdays = "Поле должно быть заполненно!";
        timeList[item.id].error = true;

        return;
      }

      if(item.startTime === "" || item.endTime === ""){
        validForm = false;
        timeList[item.id].Time = "Поле должно быть заполненно!";
        timeList[item.id].error = true;

        return;
      }
      if (item.startTime === item.endTime){
        validForm = false;
        timeList[item.id].Time = "Время начала не может === время конца!";
        timeList[item.id].error = true;

        return;
      }
      if (item.startTime > item.endTime){
        validForm = false;
        timeList[item.id].Time = "Время начала не может быть больше время конца!";
        timeList[item.id].error = true;

        return;
      }
    });
  }

  setErrors({...errors,
    validForm: validForm,
    name: name,
    sourceName: sourceName,

    folderName: folderName,
    serverIp: serverIp,

    timeList: timeList,
  })
}

