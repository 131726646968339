import React, {useEffect, useState} from 'react';

import styled from "styled-components";
import {Button, Icon} from 'semantic-ui-react'

import TvPlayer from "../TvPlayer/TvPlayer";

import ServiceAPI from '../../../services';

import {useHotkeys} from "react-hotkeys-hook";
import DimmerLoader from "../../common/Elements/Loader/DimmerLoader";


import TableTvArticle from "../TvRange/TableTvArticle";
import {updateUrlFile} from "../../../helpersFunction/TvPlayer";
import {format} from "date-fns";
import * as moment from "moment";


const TvPlayerContainer = styled.div`
  display: flex;

  margin-left: 20px;
  margin-right: 20px;
`;

const TvPlayerDiv = styled.div`
  background: #ffffff;
  padding-bottom: 20px;
  flex: 30%;
  width: 49%;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 15px;

  border-color: rgb(235,237,244);
`;

const ArticleTableList = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 97%;
  height: 800px;
`;


const TitleName = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: gray;
`;


const SinglePage =  props  => {
  const { tvRangeId } = props;

  const [tvArticleProgramOptions, setTvArticleProgramOptions] = useState({options:{}});
  const [tvDate, setTvDate] = useState({
    dateStart: null,
    dateViews: null,
    dateEnd: null,

    hour: 0,
    minute: 0,
  });

  const [tvRangeCutItem, setTvRangeCutItem] = useState({
    id: 0,
    name: '',

    startTime: '',
    endTime: '',

    sourceId: '',
    sourceName: '',
    timeList: [],
    createdDate: '',
    weekdays: '',
    keyWeekdays: '',

    folderName: '',
    serverIp: '',
    type: '',
    processed: false,
    prime: true,
    enabled: true,
  });

  const [playerState, setPlayerState] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: true,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,

    //TODO append to seek
    playedSeconds: 0,
    playbackRate: 1.0,
    loop: false,
    SinglePage: false,
  });

  const [tvArticle, setTvArticle] = useState({
    articles: []
  });

  const [saveArticle, setSaveArticle] = useState(false);
  const [errorsTvArticle, setErrorsTvArticle] = useState({'disableSave': true, articlesErrors: {} });

  const service = new ServiceAPI();

  const updateTvRangeCutItem = (data) => {
    setTvRangeCutItem({...tvRangeCutItem,
      id: data.id,
      title: data.title,

      startTime: data.startTime,
      endTime: data.endTime,

      weekdays: data.weekdays,
      keyWeekdays: data.keyWeekdays,

      date: data.date,
      type: data.type,
      sourceId: data.sourceId,
      sourceName: data.sourceName,

      folderName: data.folderName,
      serverIp: data.serverIp,
      processed: data.processed,
      prime: data.prime
    })
  };

  useEffect( ()=>{
    let options = tvArticleProgramOptions.options;
    let list = Object.keys(options);
    if(list.length){
      let lastIndex = list[list.length-1];
      changeStateTvArticle(parseInt(lastIndex), options[lastIndex][0].value,"program");
    }
  }, [tvArticleProgramOptions]);

  useEffect(()=>{
    service.getTvRangeCut(tvRangeId).then(resp => {
      let data = resp.data;
      if (data){
        updateTvRangeCutItem(data);
        // FORMAT TIME
        let dateStart = new Date(data.date);
        let dateEnd = new Date(data.date);

        let timeStart = moment(data.startTime,'HH:mm:ss');
        let timeEnd = moment(data.endTime,'HH:mm:ss');

        dateStart.setHours(timeStart.hours());
        dateStart.setMinutes(timeStart.minutes());

        let copiedDate = new Date(dateStart);

        if (timeEnd.hours() < timeStart.hours()){
          dateEnd.setDate(dateEnd.getDate() + 1)
        }
        dateEnd.setHours(timeEnd.hours());
        dateEnd.setMinutes(timeEnd.minutes());

        setTvDate({...tvDate,
          dateStart: copiedDate,
          dateEnd: dateEnd,
          dateViews: dateStart,

          minute: timeStart.minutes(),
          hour: timeStart.hours()
        });
      }
    });
  },[]);

  useEffect(()=>{
    service.getArticlesTvRangeCut(tvRangeId).then(resp => {
      setTvArticle({...tvArticle,
        articles: resp.data.results
      })
    })
  },[tvRangeCutItem.id]);

  useEffect(() => {
    // TODO err if on load page click on rewind +/-
    if (!tvDate.dateViews){
      return;
    }
    updateUrlFile(tvDate, tvRangeCutItem, setPlayerState, playerState)
  },[tvDate.minute, tvDate.hour]);

  const createArticle = () => {
    let indexId = 0;

    //GET START TIME NOW
    let date = format(tvDate.dateViews, "yyyy-MM-dd");
    let time = format(tvDate.dateViews, "HH:mm:ss");
    let start = date+'T'+time;

    // Get max value of index
    if (tvArticle.articles.length !== 0){
      const maxIndexObj = tvArticle.articles.reduce(
          (p, c) => p.id > c.id ? p : c
      );
      indexId = maxIndexObj.id + 1
    }

    setTvArticle({...tvArticle,
      articles: [...tvArticle.articles,
        {
          id: indexId,
          number: indexId+1,
          startTime: start,
          folderName: tvRangeCutItem.folderName,
          serverIp: tvRangeCutItem.serverIp,
          idTvRangeCut: tvRangeCutItem.id,
          sourceId: tvRangeCutItem.sourceId,
          sourceName: tvRangeCutItem.sourceName,

          disabled: false,

          program: '',
          programId:'',
          type: tvRangeCutItem.type,
          prime: tvRangeCutItem.prime,

          endTime: '',
          title: ''
        }
      ]
    });
    if (start){
      let obj = {start_time:start, weekday: tvRangeCutItem.keyWeekdays, source_name:tvRangeCutItem.sourceName};
      setOptionProgramByKey(indexId, obj)
    }
  };

  const setOptionProgramByKey = (key, obj) =>{
    service.getProgramByWeekDay(obj).then(resp => {
        if (resp.data && resp.data.results){
          let options = tvArticleProgramOptions.options;
          options[key] = resp.data.results;
          options[key].push({title: '-', text:'-', value: null});

          setTvArticleProgramOptions({...tvArticleProgramOptions, options: options});
        }
    });
  };

  //HOT KEY PAGE
  useHotkeys('alt+q,alt+й', () => {
    if (!tvDate.dateViews){
      return
    }
    createArticle()
  }, {}, [tvArticle, tvDate]);

  useHotkeys('alt+s,alt+w,alt+ы,alt+ц', (event, handle) => {
    if (tvArticle.articles.length !== 0){
      let lastArticle = tvArticle.articles[tvArticle.articles.length - 1];

      let tzoffset = (new Date()).getTimezoneOffset() * 60000;
      let start = new Date(tvDate.dateViews - tzoffset).toISOString().split('.')[0];

      if (event.key === 's' || event.key === 'ы') {
        changeStateTvArticle(lastArticle.id, start,"startTime");

      }else if (event.key === 'w' || event.key === 'ц')  {
        changeStateTvArticle(lastArticle.id, start,"endTime");
      }
    }
  }, {}, [tvArticle, tvDate]);

  //CHANGE TIME IN TV ARTICLE TABLE
  const changeStateTvArticle = (key, value, keyField) =>{
    let index = tvArticle.articles.findIndex(item => item.id === key);
    let ItemArticle  = tvArticle.articles;

    if(index < 0){
      return;
    }
    if(ItemArticle[index].disabled){
      return;
    }

    ItemArticle[index][keyField] = value;

    setTvArticle( {...tvArticle,
      articles: ItemArticle
    });

    //IF CHANGE START TIME CHANGE OPTIONS LIST
    if (keyField === 'startTime'){
      let start = ItemArticle[index].startTime;
      let obj = {start_time:start, weekday: tvRangeCutItem.keyWeekdays, source_name:tvRangeCutItem.sourceName};
      setOptionProgramByKey(key, obj)
    }

  };

  const isValidDateFormat = (input) => {
    if(!input) {
      return false
    }

    let length_after_dot = input.split('T')[1].split(':').length
    if (length_after_dot < 3){
      return false
    }
    return true
  };

  //VALIDATION TV ARTICLE
  useEffect(()=>{
    let articlesErrors = {};
    let disableSave = false;

    if(tvArticle.articles.length === 0){
      setErrorsTvArticle({...errorsTvArticle, disableSave: true});
      return;
    }

    tvArticle.articles.forEach((item)=> {
      let startTime = item.startTime;
      let endTime = item.endTime;
      let title = item.title;

      let errorItem = {};

      let startTimeValid = isValidDateFormat(startTime)
      let endTimeValid = isValidDateFormat(endTime)

      if(!startTimeValid){
        errorItem['time'] = true;
        disableSave = true;
      }
      if(!endTimeValid){
        errorItem['time'] = true;
        disableSave = true;
      }
      if(startTime === endTime){
        errorItem['time'] = true;
        disableSave = true;
      }

      if (startTime > endTime){
        errorItem['time'] = true;
        disableSave = true;
      }

      if(title.length < 4){
        errorItem['title'] = true;
        disableSave = true;
      }
      if (Object.keys(errorItem).length !== 0){
        articlesErrors[item.id] = errorItem;
      }
    });

    setErrorsTvArticle({...errorsTvArticle, disableSave:disableSave, articlesErrors:articlesErrors, });
  },[tvArticle]);

  const SaveArticlePlot = () => {
    setSaveArticle(true);
    tvArticle.tvRangeCutItemId = tvRangeCutItem.id;
    service.createTvPlotList(tvArticle).then(resp => {
      if(resp){
        window.location.href = "/tv-range-cut"
      }
      setSaveArticle(false);
    })
  };

  const onAddItemProgram = (key, value) => {
    let options = tvArticleProgramOptions.options;
    options[key].push({title: value, text:value, value: value});
    changeStateTvArticle(key, value, 'program')
  };

  const DeleteCreatedArticle = (index) => {
    let articleIndex = tvArticle.articles.findIndex(item => item.id === index);
    let ItemArticle  = [...tvArticle.articles];
    ItemArticle.splice(articleIndex, 1);
    setTvArticle( {...tvArticle,
      articles: ItemArticle
    });

    //CLEAN OPTIONS
    delete tvArticleProgramOptions.options[index];
    setTvArticleProgramOptions({...tvArticleProgramOptions, options: tvArticleProgramOptions.options});

  };

  return (
    <>
      <DimmerLoader
          textOfLoader="Сохранение ..."
          active={saveArticle}
      />

      <TvPlayerContainer >
        {/* TV PLAYER */}
        <TvPlayerDiv>
          <TitleName>
              <h2>
                {tvRangeCutItem.title}
              </h2>
          </TitleName>

          <TvPlayer
              playerState={playerState}
              setPlayerState={setPlayerState}
              tvDate={tvDate}
              setTvDate={setTvDate}
          />
        </TvPlayerDiv>
      </TvPlayerContainer>

      {/* Table Sections */}
      <ArticleTableList>
        <TableTvArticle
          tvArticle={tvArticle}
          errorsTvArticle={errorsTvArticle}
          changeStateTvArticle={changeStateTvArticle}

          tvArticleProgramOptions={tvArticleProgramOptions}
          onAddItemProgram={onAddItemProgram}
          DeleteCreatedArticle={DeleteCreatedArticle}
        />

        <Button disabled={errorsTvArticle['disableSave']} onClick={SaveArticlePlot} positive>
          <Icon name='save' />
          Сохранить все сюжеты
        </Button>

      </ArticleTableList>

    </>
  )
};
export default SinglePage;
