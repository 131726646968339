import React from 'react';

import {Button, Icon} from 'semantic-ui-react'
import PropTypes from "prop-types";


const SaveButton = (props) => {
  const {OnClick, disabled, text} = props;
  return(
      <Button
          positive
          disabled={disabled}
          onClick={OnClick}
      >
        <Icon name="save" />
        {text}
      </Button>
  )
};

export default SaveButton;


SaveButton.propTypes = {
  OnClick: PropTypes.any,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

SaveButton.default = {
  OnClick: () => {},
  text: '',
  disabled: true,
};