import React from "react";
import {Loader} from "semantic-ui-react";
import styled from "styled-components";
import PropTypes from "prop-types";


const LoaderStyled = styled.div`
  padding-top: 40px;
`;


const PageLoader = (props) => {
  const {Load} = props;
  return(
    <>
      {!Load ?
          <LoaderStyled>
            <Loader active inline='centered' />
          </LoaderStyled>
          :
          <> </>
      }
    </>
  )
};

export default PageLoader;


PageLoader.propTypes = {
  Load: PropTypes.bool,
};

PageLoader.default = {
  Load: true,
};