import React from 'react';
import styled from 'styled-components';
import {Input} from 'semantic-ui-react'
import {SettingItemStyled} from "./styled";
import { Form } from 'semantic-ui-react'

export const ItemTitleStyled = styled.div`
  text-transform: uppercase;
  flex: 25%;
`;

export const ItemValueStyled = styled.div`
  flex:65%;
  max-wight: 10px;
`;


const DateTimeItem = props => {
  const { ItemTitle, ItemValue, onChangeFunction, error } = props;

  return (
      <SettingItemStyled>

        <ItemTitleStyled>
          <label>
            {ItemTitle} :
          </label>
        </ItemTitleStyled>

        <ItemValueStyled>
          <Form.Input
              error={error}
              value={ItemValue}
              type="datetime-local"
              size="small"
              step="1"
              onChange={onChangeFunction}
          />
        </ItemValueStyled>

      </SettingItemStyled>
  );
};

export default DateTimeItem;
