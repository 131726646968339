import React from 'react';
import PropTypes from "prop-types";

import { Map } from 'react-lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CheckBoxSelected from "../../common/Elements/Button/CheckBoxSelected";
import PageLoader from "../../common/Elements/Loader/PageLoader";
import TableRowYouTubeArticle from "../../common/Elements/YouTube/TableRowYouTube";


const ReportListContainerStyled = styled.div`
  overflow: auto;
`;

export const ContentWrapperStyled = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const ItemContainer = styled.div`
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

const YouTubeArticlesList = ( props ) => {

    const { YTLoad, YTArticlesRanges, onClickCheckBox, selectedItems } = props;


    return (
        <ContentWrapperStyled>
            <PageLoader
                Load={YTLoad}
            />
            <ReportListContainerStyled id="rangeListContainer">
                <Map
                    collection={YTArticlesRanges}
                    iteratee={value => {
                        return (
                            <div key={value.id} >
                                <CheckBoxSelected
                                    selectedItems={selectedItems.indexOf(value.id) !== -1}
                                    onClickCheckBox={()=>onClickCheckBox(value.id)}
                                />

                                <ItemContainer>
                                    <Link
                                        key={value.id}
                                        to={`/youtube-article/${value.id}`}
                                        exact={"true"}
                                    >
                                        <TableRowYouTubeArticle
                                            YTArticleId={value.id}
                                            YTArticleUrl={value.url}
                                            YTArticleTitle={value.title}
                                            YTArticleHumanProcessed={value.humanProcessed}
                                            YTArticleYoutubeParsed={value.youtubeParsed}
                                            YTArticleError={value.error}
                                        />
                                    </Link>
                                </ItemContainer>
                            </div>
                        );
                    }}
                />
            </ReportListContainerStyled>
        </ContentWrapperStyled>
    )
};

export default YouTubeArticlesList;

YouTubeArticlesList.propTypes = {
    TvRangeLoad: PropTypes.bool,
    TvRanges: PropTypes.array,
    onClickCheckBox: PropTypes.any,
    selectedItems: PropTypes.array
};

YouTubeArticlesList.defaultProps = {
    TvRangeLoad: false,
    TvRanges: [],
    onClickCheckBox: ()=>{},
    selectedItems: []
};
