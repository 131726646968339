import React from 'react';
import { useParams } from 'react-router-dom';

import Header from "../containers/Header";
import SinglePageCut from "../containers/TvRangeCut/SinglePageCut";


const SingleTvRangeCutPage = () => {
  const { tvRangeId } = useParams();

  return (
      <>
        <Header />
        <SinglePageCut tvRangeId={tvRangeId}/>
      </>
  )
};
export default SingleTvRangeCutPage;