import React from 'react';
import { useParams } from 'react-router-dom';

import Header from "../containers/Header";
import SinglePage from "../containers/TvArticles/SinglePage";


const SingleTvArticle= () => {
  const { tvArticleId } = useParams();
  return (
      <>
        <Header />
        <SinglePage tvArticleId={tvArticleId}/>
      </>
  )
};
export default SingleTvArticle;