import React from 'react';
import styled from 'styled-components';
import Column from "../Table/ColumnItem";
import PropTypes from "prop-types";


export const TableRowStyled = styled.div`
  display: flex;
  border-bottom: 1px solid;
  padding: 8px 0;
  padding-top: 10px;
  font-size: 15px;
  
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    background-color: inherit;
  }
`;


const TableHeaderYoutubeArticles = (props) => {
    const { handleOrderingList, orderingList } = props;

    return (
        <TableRowStyled>
            <Column
                name={"url"}
                text={"Url"}
                orderingList={orderingList}
                handleOrderingList={handleOrderingList}
            />
            <Column
                name={"title"}
                text={"Заговолок"}
                orderingList={orderingList}
                handleOrderingList={handleOrderingList}
            />
            <Column
                name={"human_processed"}
                text={"human_processed"}
                orderingList={orderingList}
                handleOrderingList={handleOrderingList}
            />
            <Column
                name={"youtube_parsed"}
                text={"youtube_parsed"}
                orderingList={orderingList}
                handleOrderingList={handleOrderingList}
            />
        </TableRowStyled>
    );
};

export default TableHeaderYoutubeArticles;


TableHeaderYoutubeArticles.propTypes = {
    handleOrderingList: PropTypes.func,
    orderingList: PropTypes.array
};

TableHeaderYoutubeArticles.defaultProps = {
    handleOrderingList: PropTypes.func,
    orderingList: PropTypes.array
};