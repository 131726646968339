

export function selectCheckBox(event, selectedItems, setSelectedItems) {
  let idItems = selectedItems.selectedItems;
  let index = idItems.indexOf(event);
  if (index === -1){
    idItems.push(event)
  }else{
    idItems.splice(index, 1)
  }
  setSelectedItems({...selectedItems, selectedItems: idItems})

}