import React from 'react';
import styled from 'styled-components';
import { Input } from 'semantic-ui-react'
import AddButton from "../Button/AddButton";


const MainContainer = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  
`;
const DivContainer = styled.div`
  width: 380px;
  margin-left: 10px;
  padding-right: 10px;
`;
const DicCont = styled.div`
  display: flex;
  padding-bottom: 20px;
`;

const InputCreateVideo = (props) => {
    const {
        OnClick,
        OnChange,
        value,
        validationError
    } = props;

    return(
        <>
            <MainContainer>
                <DicCont>
                    <DivContainer>
                        <Input
                            fluid
                            error={validationError}
                            icon='linkify'
                            placeholder='ссылка для обработки'
                            onChange={OnChange}
                            value={value}
                        />
                    </DivContainer>

                    <AddButton
                        text="Сохранить для обработки"
                        OnClick={OnClick}
                        disabled={validationError}
                    />

                </DicCont>

            </MainContainer>
        </>
    )
}


export default InputCreateVideo;