import React from 'react';
import {Button, Icon} from 'semantic-ui-react'
import PropTypes from "prop-types";


const AddButton = (props) => {
  const {OnClick, text, disabled} = props;
  return(
      <Button
          primary
          disabled={disabled?disabled : false}
          onClick={OnClick}
      >
        <Icon name='add' />
        {text}
      </Button>
  )
};

export default AddButton;


AddButton.propTypes = {
  OnClick: PropTypes.any,
  text: PropTypes.string,
};

AddButton.default = {
  OnClick: () => {},
  text: '',
};