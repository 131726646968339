import React, { useState, useEffect, useRef } from 'react';

import ServiceAPI from '../../../services';
import styled from "styled-components";
import {AddTimeList, changeTimeList, DeleteTimeListItem} from "../../../helpersFunction/TimeListFunc";
import {
  getWeekdaysOption,
  searchSite,
  selectSearchResult,
} from "../../../helpersFunction/TvRange";
import DimmerLoader from "../../common/Elements/Loader/DimmerLoader";
import {changeState} from "../../../helpersFunction/ChnageState";
import TvProgramForm from "./TvProgramForm";

import {validateTvProgram} from "../../../helpersFunction/TvProgram";
import {throttle} from "lodash";
import UpdateButton from "../../common/Elements/Button/UpdateButton";

const UpdateButtonDiv = styled.div`
  padding-top: 15px;
  width: 95%;
  margin-left: auto;
  padding-bottom: 15px;
`;

const SinglePage =  props  => {
  const { tvProgramId } = props;

  const [tvProgram, setTvProgram] = useState({
    id: 0,
    name: '',
    sourceId: '',
    sourceName: '',
    timeList: [],
    processed: false,
  });

  const [errors, setErrors] = useState({
    validForm: false,
    name: '',
    sourceName: '',
    timeList: '',

    weekdays: '',

    folderName: '',
    serverIp: '',
  });

  const [updateTvProgramFlag, setUpdateTvProgramFlag] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const [optionsWeekdays, setOptionsWeekday] = useState([{"key": "-", "value": "-", "text": "-"}]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoad, setSearchLoad] = useState(false);
  const [selectSearch, setSelectSearch] = useState({});

  const service = new ServiceAPI();

  const ChangeTimeListItem = (key, value, keyField) => {
    changeTimeList(tvProgram, setTvProgram, keyField, key, value);
  };

  // Get option to select weekday
  useEffect(()=>{
    getWeekdaysOption(setOptionsWeekday);
  },[]);

  // Update state data of tvProgram
  const updateTvProgram = (data) => {
    setTvProgram({...tvProgram,
      processed: data.processed,
      sourceId: data.sourceId,
      sourceName: data.sourceName,
      timeList: data.timeList,
      name: data.name,
      id: data.id,
    })
  };


  //Get obj from server
  useEffect(()=>{
    service.getTvProgram(tvProgramId).then(resp => {
      let data = resp.data;
      if (data){
        updateTvProgram(data);
      }
    });
  },[]);

  const throttledSearch = useRef(throttle(
      (setSearchLoad, setSearchResults, searchValue) =>
          searchSite(setSearchLoad, setSearchResults, searchValue),
      700)
  );
  //Search field of source from crawler
  useEffect(()=>{
    throttledSearch.current(setSearchLoad, setSearchResults, searchValue)
  }, [searchValue]);

  useEffect(()=>{
    selectSearchResult(setSearchValue, setTvProgram, tvProgram, selectSearch);
  }, [selectSearch]);

  useEffect(() => {
    validateTvProgram(tvProgram, setErrors, errors)
  },[tvProgram]);

  const UpdateTvProgram = () => {
    setUpdateTvProgramFlag(true);
    service.updateTvProgram(tvProgram, tvProgramId).then(resp => {
      let data = resp.data;
      if (data){
        updateTvProgram(data);
      }
      setUpdateTvProgramFlag(false);
    })
  };

  const DeleteTimeList = (id) => {
    DeleteTimeListItem(id, tvProgram, setTvProgram);
  };

  const DeleteAllTimeListItems = () => {
    setTvProgram({...tvProgram, timeList: []})
  };

  return (
      <>
        <DimmerLoader
            textOfLoader="Saving ..."
            active={updateTvProgramFlag}
        />
        <TvProgramForm
            tvProgram={tvProgram}
            errorsTvProgram={errors}

            OnChangeName={e => changeState("name",e.target.value, tvProgram, setTvProgram)}
            OnChangeSourceName={e => changeState("sourceName",e.target.value, tvProgram, setTvProgram)}

            OnChangeProcessed={(e, v) => changeState("processed", v.checked, tvProgram, setTvProgram)}

            AddTimeListButton={()=>AddTimeList(tvProgram, setTvProgram)}

            ChangeTimeListItem={ChangeTimeListItem}
            optionsWeekdays={optionsWeekdays}

            searchValue={searchValue}
            onChangeSearchValue={(e, {value}) => setSearchValue(value)}
            searchResults={searchResults}
            searchLoad={searchLoad}
            onSelectSearch={(e, data)=>setSelectSearch(data.result)}

            blockDelete={false}

            disabledDeleteButton={false}
            DeleteTimeListItem={DeleteTimeList}
            DeleteTimeList={DeleteAllTimeListItems}
        />
        <UpdateButtonDiv>
          <UpdateButton
              disabled={errors.validForm}
              OnClick={UpdateTvProgram}
              text={"Обновить запись"}
          />
        </UpdateButtonDiv>
      </>
  )
};
export default SinglePage;
