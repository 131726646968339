import React from 'react';
import PropTypes from "prop-types";

import { Map } from 'react-lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CheckBoxSelected from "../../common/Elements/Button/CheckBoxSelected";
import TableRowTvRangeCut from "../../common/Elements/TvRangeCut/TableRowTvRangeCut";
import PageLoader from "../../common/Elements/Loader/PageLoader";


const ReportListContainerStyled = styled.div`
  overflow: auto;
`;

export const ContentWrapperStyled = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const ItemContainer = styled.div`
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

const TvRangeListCut = ( props ) => {

  const { TvRangeLoad, TvRanges, onClickCheckBox, selectedItems } = props;

  return (
      <ContentWrapperStyled>
        <PageLoader
            Load={TvRangeLoad}
        />
        <ReportListContainerStyled id="rangeListContainer">
          <Map
              collection={TvRanges}
              iteratee={value => {
                return (
                    <div key={value.id} >
                      <CheckBoxSelected
                          selectedItems={selectedItems.indexOf(value.id) !== -1}
                          onClickCheckBox={()=>onClickCheckBox(value.id)}
                      />

                      <ItemContainer>
                        <Link
                            key={value.id}
                            to={`/tv-range-cut/${value.id}`}
                            exact={"true"}
                        >
                          <TableRowTvRangeCut
                              tvProgramId={value.id}
                              tvRangeTitle={value.title}
                              tvRangeStartTime={value.startTime}
                              tvRangeEndTime={value.endTime}
                              tvRangeDate={value.date}
                              tvRangeWeekdays={value.weekdays}
                              tvRangeProcessed={value.processed}
                              tvRangePrime={value.prime}
                          />
                        </Link>
                      </ItemContainer>

                    </div>
                );
              }}
          />
        </ReportListContainerStyled>
      </ContentWrapperStyled>
  )
};

export default TvRangeListCut;

TvRangeListCut.propTypes = {
  TvRangeLoad: PropTypes.bool,
  TvRanges: PropTypes.array,
  onClickCheckBox: PropTypes.any,
  selectedItems: PropTypes.array
};

TvRangeListCut.defaultProps = {
  TvRangeLoad: false,
  TvRanges: [],
  onClickCheckBox: ()=>{},
  selectedItems: []
};
