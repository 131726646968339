import React from 'react';
import styled from "styled-components";
import { Dropdown, Input } from 'semantic-ui-react'

const DivContainer = styled.div`
  width: 370px;
`;

const SearchButton = (props) => {
  const {
    OnChange,
    SearchFieldChange,
    dataLoaded,
    optionsSearch,
    searchValue
  } = props;

  return(
      <DivContainer>
          <Input
              fluid
              loading={!dataLoaded}
              action={
                  <Dropdown button basic floating
                            options={optionsSearch}
                            defaultValue={optionsSearch[0].value}
                            onChange={SearchFieldChange}
                  />
              }
              icon='search'
              iconPosition='left'
              placeholder='Поиск...'
              onChange={OnChange}
              value={searchValue}
          />
      </DivContainer>
  )
};

export default SearchButton;
