export function validateTvArticle(tvArticle, setErrors, errors) {
  let title = false;
  let text = false;
  let validForm = true;

  if (!tvArticle.title || tvArticle.title.length < 4){
    validForm = false;
    title = 'Значение должно быть больше 2!'
  }
  if (!tvArticle.text || tvArticle.text.length < 4){
    validForm = false;
    text = 'Значение должно быть больше 2!'
  }
  setErrors({...errors,
    validForm: validForm,
    title: title,
    text: text,
  })
}

export function validationCreateArticle(tvArticle, setErrors, errors){
  let title = false;
  let sourceName = false;
  let validForm = true;

  let serverIp = false;
  let folderName = false;

  let startTime = false;
  let endTime = false;

  if (!tvArticle.title || tvArticle.title.length < 2){
    validForm = false;
    title = 'Значение должно быть больше 2!'
  }

  if (!tvArticle.folderName || tvArticle.folderName.length < 2){
    validForm = false;
    folderName = 'Значение должно быть больше 2!'
  }

  if (!tvArticle.serverIp || tvArticle.serverIp.length < 2){
    validForm = false;
    serverIp = 'Значение должно быть больше 2!'
  }

  if (!tvArticle.sourceName || tvArticle.sourceName.length < 2){
    validForm = false;
    sourceName = 'Значение должно быть больше 2!'
  }

  if (!tvArticle.startTime){
    validForm = false;
    startTime = 'Не может быть пустым !'
  }

  if (!tvArticle.endTime){
    validForm = false;
    endTime = 'Не может быть пустым !'
  }

  if (!endTime || !startTime){
    if (tvArticle.endTime < tvArticle.startTime){
      validForm = false;
      endTime = 'Дата конца меньше чем дата старта!'
      startTime = 'Дата конца меньше чем дата старта!'
    }

    let start = new Date(tvArticle.startTime)
    let end = new Date(tvArticle.endTime)
    let hours = parseInt((end - start)/(1000*60*60));

    if(hours > 2){
      validForm = false;
      endTime = 'Дата не может быть больше 2 часов!'
      startTime = 'Дата не может быть больше 2 часов!'
    }
  }

  setErrors({...errors,
    validForm: validForm,
    title: title,
    sourceName: sourceName,

    folderName: folderName,
    serverIp: serverIp,

    endTime: endTime,
    startTime: startTime,
  })
}
