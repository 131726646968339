import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

export const NavItemStyled = styled(NavLink)`
  font-size: 18px;
  padding: 0px 16px;
  text-decoration: none;
  color: rgb(139, 138, 142);
  :hover {
    color: rgb(56, 55, 58);
    cursor: pointer;
    font-weight: 600;
  }
  &.active {
    color: #000000;
  }
`;

const NavItem = ({ link, linkTitle, access }) => {
  if (!access)
    return null
  return (
      <NavItemStyled
          to={link}
          exact={true}
      >
        {linkTitle}
      </NavItemStyled>
  );
};

export default NavItem;

NavItem.propTypes = {
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  exact: PropTypes.bool
};

NavItem.defaultProps = {
  link: '',
  linkTitle: '',
  exact: false
};
