import React, {useEffect, useRef, useState} from 'react';

import styled from "styled-components";
import ServiceAPI from '../../../services';

import {changeState} from "../../../helpersFunction/ChnageState";
import DimmerLoader from "../../common/Elements/Loader/DimmerLoader";
import SaveButton from "../../common/Elements/Button/SaveButton";
import {validationCreateArticle} from "../../../helpersFunction/TvArticle";
import {searchSite, selectSearchResult} from "../../../helpersFunction/TvRange";
import {throttle} from "lodash";

import TvArticleTextCreator from "../../common/Elements/TvArticles/TextCreator";
import TvPlayer from "../TvPlayer/TvPlayer";
import {GetUrlFromDate} from "../../../helpersFunction/UrlTime";
import * as moment from "moment";
import {updateUrlFile} from "../../../helpersFunction/TvPlayer";

const SaveButtonContainer = styled.div`
  padding-top: 10px;
  padding-left: 20px;
`;

const SinglePage = () => {
    //Date start - end 2 hour err
    const [updateArticleFlag, setUpdateArticleFlag] = useState(false);
    const [playerState, setPlayerState] = useState({
        url: null,
        pip: false,
        playing: false,
        controls: true,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,

        //TODO append to seek
        playedSeconds: 0,
        playbackRate: 1.0,
        loop: false,
        SinglePage: false,
    });
    const [tvDate, setTvDate] = useState({
        dateStart: null,
        dateViews: null,
        dateEnd: null,

        hour: 0,
        minute: 0,
    });
    const [errors, setErrors] = useState({
        validForm: false,
        title: 'Короткое название',
        sourceName: true,
        sourceId: true,
        folderName: true,

        startTime: true,
        endTime: true,
    });
    const [tvArticle, setTvArticle] = useState({
        title: '',
        text: '',

        sourceId: null,
        sourceName: null,

        startTime: null,
        endTime: null,

        serverIp: null,
        folderName: null,
        program: null,
        type: '',
        prime: true,
        humanProcessed: false,
        audioProcessed: false,
    });

    // const [search, setSearch] = useState({
    //     searchValue: '',
    //     searchResults: [],
    //
    //     searchLoad: false,
    //     selectSearch: '',
    // });

    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchLoad, setSearchLoad] = useState(false);
    const [selectSearch, setSelectSearch] = useState({});

    const service = new ServiceAPI();

    useEffect(() => {
        validationCreateArticle(tvArticle, setErrors, errors)
    }, [tvArticle]);

    const createArticle = () => {
        setUpdateArticleFlag(true);
        service.createTvPlot(tvArticle).then(resp => {
            if (resp) {
                window.location.href = "/tv-articles"
            }
            setUpdateArticleFlag(false);
        })
    }
    const throttledSearch = useRef(throttle(
        (setSearchLoad, setSearchResults, searchValue) =>
            searchSite(setSearchLoad, setSearchResults, searchValue),
        700)
    );
    useEffect(() => {
        throttledSearch.current(setSearchLoad, setSearchResults, searchValue)
    }, [searchValue]);

    useEffect(() => {
        selectSearchResult(setSearchValue, setTvArticle, tvArticle, selectSearch);
    }, [selectSearch]);

    const OnClickSelectSearch = (e, data) => {
        setSelectSearch(data.result)
    };
    useEffect(() => {
        if (tvArticle.startTime && tvArticle.serverIp) {
            let date = new Date(tvArticle.startTime)
            let dateStart = new Date(date)
            let endTime = new Date(date)

            if (tvArticle.endTime && !errors.endTime) {
                endTime = new Date(tvArticle.endTime)
            } else {
                let timeEnd = moment(endTime, 'HH:mm:ss');
                endTime.setMinutes(timeEnd.minutes() + 10)
            }
            setTvDate({...tvDate, dateViews: date, dateStart: dateStart, dateEnd: endTime})
            let url = GetUrlFromDate(
                `https://${tvArticle.serverIp}/video`,
                tvArticle.folderName,
                date,
                tvArticle.type
            )
            setPlayerState({...playerState, url: url})
        } else {
            setPlayerState({...playerState, url: ''})
        }

    }, [tvArticle.startTime, tvArticle.serverIp, tvArticle.endTime, errors.startTime, errors.endTime])

    useEffect(() => {
        if (!tvDate.dateViews) {
            return;
        }
        updateUrlFile(tvDate, tvArticle, setPlayerState, playerState)
    }, [tvDate.minute, tvDate.hour]);

    return (
        <>
            <DimmerLoader
                textOfLoader="Сохранение ..."
                active={updateArticleFlag}
            />
            <TvPlayer
                playerState={playerState}
                setPlayerState={setPlayerState}
                tvDate={tvDate}
                setTvDate={setTvDate}
            />
            <TvArticleTextCreator
                articleError={errors}

                titleValue={tvArticle.title}
                onChangeTitle={(e) => changeState("title", e.target.value, tvArticle, setTvArticle)}

                sourceName={tvArticle.sourceName}
                sourceProgram={tvArticle.program}

                type={tvArticle.type}
                OnChangeType={e => changeState("type", e.target.value, tvArticle, setTvArticle)}

                OnChangeSourceName={e => changeState("sourceName", e.target.value, tvArticle, setTvArticle)}
                searchValue={searchValue}
                onChangeSearchValue={(e, {value}) => setSearchValue(value)}
                searchResults={searchResults}
                searchLoad={searchLoad}
                onSelectSearch={(e, data) => OnClickSelectSearch(e, data)}

                startTime={tvArticle.startTime}
                endTime={tvArticle.endTime}
                OnChangeDateEnd={e => changeState("endTime", e.target.value, tvArticle, setTvArticle)}
                OnChangeDateStart={e => changeState("startTime", e.target.value, tvArticle, setTvArticle)}

                programValue={tvArticle.program}
                onChangeProgram={e => changeState("program", e.target.value, tvArticle, setTvArticle)}

                folderName={tvArticle.folderName}
                errorFolderName={errors.folderName}
                OnChangeFolderName={e => changeState("folderName", e.target.value, tvArticle, setTvArticle)}

                serverIp={tvArticle.serverIp}
                errorServerIp={errors.serverIp}
                OnChangeServerIp={e => changeState("serverIp", e.target.value, tvArticle, setTvArticle)}
            />
            <SaveButtonContainer>
                <SaveButton
                    disabled={!errors.validForm}
                    OnClick={createArticle}
                    text={'Сохранить'}
                />
            </SaveButtonContainer>
        </>
    )
};
export default SinglePage;
