import React from "react";

import {Form} from "semantic-ui-react";
import InputItem from "../../common/Elements/Form/Items/InputItem";

import SourceNameItem from "../../common/Elements/Form/Items/SourceNameItem";
import BoolItem from "../../common/Elements/Form/Items/BoolItem";
import TimeListMap from "../TimeList/TimeListMap";
import AddButton from "../../common/Elements/Button/AddButton";

import DeleteButton from "../../common/Elements/Button/DeleteButton";
import styled from "styled-components";


const TimeListWrapped = styled.div`
  background: #f7f7f757;
  border-radius: 10px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 15px;
  
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  
  border-color: rgb(169, 169, 169);
`;

const TvProgramForm = (props) => {
  const {
    tvProgram,
    errorsTvProgram,

    OnChangeName,
    OnChangeSourceName,

    OnChangeProcessed,

    AddTimeListButton,

    ChangeTimeListItem,
    optionsWeekdays,

    searchValue,
    onChangeSearchValue,
    searchResults,
    searchLoad,
    onSelectSearch,

    blockDelete,

    DeleteTimeList,
    disabledDeleteButton,
    DeleteTimeListItem
  } = props;

  return (
      <>
        <Form>
          <Form.Field>
            <InputItem
                ItemTitle="Название программы"
                ItemId="name"
                ItemValue={tvProgram.name}
                ValidationItem={errorsTvProgram.name}
                onChangeFunction={OnChangeName}
            />
          </Form.Field>

          <Form.Field>
            <SourceNameItem
                ItemTitle="Название источника"
                ItemId="sourceName"
                ItemValue={tvProgram.sourceName}
                ValidationItem={errorsTvProgram.sourceName}
                onChangeFunction={OnChangeSourceName}

                searchValue={searchValue}
                onChangeSearchValue={onChangeSearchValue}
                searchResults={searchResults}
                searchLoad={searchLoad}
                onSelectSearch={onSelectSearch}

                disabled={true}
            />
          </Form.Field>

          <Form.Field>
            <BoolItem
                ItemTitle="Обработка"
                ItemId="processed"
                ItemValue={tvProgram.processed}
                onChangeFunction={OnChangeProcessed}
            />
          </Form.Field>

          <TimeListWrapped>
            <TimeListMap
                collection={tvProgram.timeList}
                errors={errorsTvProgram}
                ChangeTimeListItem={ChangeTimeListItem}
                optionsWeekdays={optionsWeekdays}

                disabledDeleteButton={disabledDeleteButton}
                DeleteTimeListItem={DeleteTimeListItem}
            />

            <AddButton
                OnClick={AddTimeListButton}
                text={"Добавить"}
            />

            {!blockDelete && tvProgram.timeList.length  ? (
                <DeleteButton
                    OnClick={DeleteTimeList}
                    text={'Убрать все объекты'}
                    icon={'trash'}
                />
            ) : (<></>)
            }

          </TimeListWrapped>
        </Form>
      </>
  )
};
export default TvProgramForm;