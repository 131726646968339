import React from "react";

import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import { Input} from "semantic-ui-react";
import { Dropdown } from 'semantic-ui-react'
import {Button} from "semantic-ui-react";



const TvArticleRow =  props  => {
  const {
    index,
    num,
    startTime,
    endTime,
    title,
    changeState,
    errorTvArticle,
    disabled,

    tvArticleProgramOptions,
    onAddItemProgram,
    optionSelectTvProgram,
    DeleteCreatedArticle
  } = props;

  var options = tvArticleProgramOptions;
  if (disabled && optionSelectTvProgram){
    options = [{text:optionSelectTvProgram, value:optionSelectTvProgram}]
  }
  return(
      <>
        <Table.Row>

          <Table.Cell>
            {/* TODO THINK IT NEED */}
            {/*<Checkbox*/}
            {/*    disabled={disabled}*/}
            {/*    type='checkbox'*/}
            {/*/>*/}
            <Button
                icon={'trash'}
                disabled={disabled }
                onClick={() => DeleteCreatedArticle(index)}
            />
          </Table.Cell>

          <Table.Cell textAlign='center'>{num}</Table.Cell>

          <Table.Cell error={!!errorTvArticle}>
            <Input
              disabled={disabled}
              error={errorTvArticle? errorTvArticle.time : false}
              type="datetime-local"
              size="small"
              value={startTime}
              step="1"
              onChange={e => changeState(index, e.target.value, "startTime")}
            />
          </Table.Cell>

          <Table.Cell error={!!errorTvArticle}>
            <Input
                disabled={disabled}
                error={errorTvArticle? errorTvArticle.time : false}
                type="datetime-local"
                size="small"
                value={endTime}
                step="1"
                onChange={e => changeState(index, e.target.value, "endTime")}

            />
          </Table.Cell>

          <Table.Cell error={!!errorTvArticle}>
            <TextArea
                disabled={disabled}
                placeholder='Заголовок...'
                value={title}
                style={{ minHeight: 65, maxHeight: 65, minWidth:350, maxWidth:350 }}
                onChange={e => changeState(index, e.target.value, "title")}
            />
          </Table.Cell>

          <Table.Cell error={!!errorTvArticle}>
            <Dropdown
                style={{minWidth:200, maxWidth:200, textOverflow: 'ellipsis'}}
                disabled={disabled}
                options={options}
                placeholder='Выбор программы'
                search
                selection
                fluid
                allowAdditions
                value={optionSelectTvProgram}
                onAddItem={(e, {value}) => onAddItemProgram(index, value)}
                onChange={(e, {value}) => changeState(index, value, "program")}
            />
          </Table.Cell>
        </Table.Row>
      </>
  )

};

export default TvArticleRow;
