import React from 'react';

import Header from "../containers/Header";
import TabCreate from "../containers/TabPage";
import CreatePageTvRange from "../containers/TvRange/CreatePage";


const CreateTvRangePage = () => {
  return (
      <>
        <Header />

        <TabCreate
            link={'/tv-range'}
            showButtonPage={false}
            showButton={false}
            showCreateButton={true}
            showListButton={true}
        />

        <CreatePageTvRange />
      </>
  )
};
export default CreateTvRangePage;