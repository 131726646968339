
export const AddTimeList = (state, setState) =>{
  let indexId = 0;

  // Get max value of index
  if (state.timeList.length !== 0){
    const maxIndexObj = state.timeList.reduce(
        (p, c) => p.id > c.id ? p : c
    );
    indexId = maxIndexObj.id + 1;
  }
  setState( {...state,
    timeList: [...state.timeList,
      {
        id: indexId,
        startTime: '00:00',
        endTime: '00:00',
        weekdays: '-',
        enabled: true,
      }],
  });
};

export const DeleteTimeList = (state, setState, ) => {
  setState( {...state,
    timeList:[]
  });
};

export function changeTimeList(tvState, setTvState, keyField, key, value) {
  let index = tvState.timeList.findIndex(item => item.id === key);

  let newTimeList  = tvState.timeList;
  newTimeList[index][keyField] = value;

  setTvState( {...tvState,
    timeList: newTimeList
  });
}

export function DeleteTimeListItem(id, tvState, setTvState){
  let timeListItems = tvState.timeList;
  let index =timeListItems.findIndex(p => p.id === id);
  if (index === -1){
    return
  }else{
    timeListItems.splice(index, 1)
  }
  setTvState({...tvState, timeList: timeListItems})
}