import React, {useEffect, useState} from 'react';
import ReactPlayer from "react-player";
import {Button, Input} from "semantic-ui-react";
import styled from "styled-components";
import TimeRange from 'react-timeline-range-slider'
import { format } from 'date-fns'
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {useHotkeys} from "react-hotkeys-hook";
import PropTypes from "prop-types";


const ItemTh = styled.th`
  padding-right: 15px;
`;

const ItemTr = styled.div`
  height: 55px;
  width: 100%;
`;

const ControllerBlock = styled.div`
  padding-top: 35px;
  padding-left: 80px;
  display: table;
`;

const PlayerCon = styled.div`
  float: left;
  padding-left: 100px;
  text-align: -webkit-center;
`;
const ButtonRewind = styled.div`
  padding-top: 15px;
  margin-left: auto;
  margin-right: auto;
`;

const TimeContainer = styled.div`
  width: 100%;
  box-sizing: inherit;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 10px;
`;

const TimeSections = styled.div`
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 80%;
  font-weight: bold;
`;
const TimeSectionsOne = styled.div`
  text-align: left;
  flex: 30%;
`;
const TimeSectionsTwo = styled.div`
  text-align: center;
  flex: 30%;
`;

const ContainerPlayer = styled.div`
    height: auto;
    min-height: 470px;
`;

const SeekContainer = styled.div`
  margin-left: 100px;
  width: 720px;
`;

const TvPlayer =  props  => {
  const { playerState, setPlayerState, tvDate, setTvDate, YtSeekLine } = props;
  const [HotKeysRemote, setHotKeysRemote] = useState(false);
  var player = null;

  //TODO need refactoring
  const handleProgress = state => {
    if (!playerState.seeking) {
      setPlayerState({...playerState, played: state.played, playedSeconds: state.playedSeconds});
    }
  };

  const ref = players => {
    player = players
  };

  const handlePlayer = (value) => {
    setPlayerState({...playerState, playing: value })
  };

  const handleSetPlaybackRate = e => {
    setPlayerState({...playerState, playbackRate: parseFloat(e.target.value) })
  };

  const PlusSeconds = (value) => {
    let sec = parseInt(playerState.playedSeconds) + parseInt(value);
    setPlayerState({...playerState, playedSeconds: sec});

    player.seekTo(sec)
  };
  const seekPlayer = (value) => {
    setPlayerState({ ...playerState, played: value})
    player.seekTo(value)
  }
  useHotkeys('right', () => {
    let sec = parseInt(playerState.playedSeconds) + 5;
    setPlayerState({...playerState, playedSeconds: sec});
    setHotKeysRemote(true)
  }, {}, [playerState, HotKeysRemote]);

  useHotkeys('left', () => {
    let sec = parseInt(playerState.playedSeconds) - 5;
    setPlayerState({...playerState, playedSeconds: sec});
    setHotKeysRemote(true)
  }, {}, [playerState, HotKeysRemote]);

  useEffect(() => {
    if(HotKeysRemote){
      if(player){
        player.seekTo(playerState.playedSeconds);
      }
      setHotKeysRemote(false)
    }
  }, [HotKeysRemote]);

  useEffect(()=>{
    if (!tvDate.dateViews){
      return
    }
    if(playerState.SinglePage===false){

      let date = new Date(tvDate.dateViews);
      date.setSeconds(playerState.playedSeconds);
      let min = date.getMinutes();

      if (min === parseInt(tvDate.minute)){
        setTvDate({...tvDate, dateViews: date})
      }else{
        setTvDate({...tvDate, dateViews: date, minute: min})
      }

    }else{

      let date = new Date(tvDate.dateStart);
      date.setSeconds(date.getSeconds() +  playerState.playedSeconds);
      setTvDate({...tvDate, dateViews: date})

    }
  }, [playerState.playedSeconds]);

  const rewindTime = (date) =>{
    // GET 0 because func return two elements
    let min = date[0].getMinutes();
    setTvDate({...tvDate, dateViews: date[0], minute: min});

    if(playerState.SinglePage) {
      let time = new Date(date[0] - tvDate.dateStart);

      if (player){
        let secondsSeek = time.getTime()/1000;
        if (secondsSeek< 1){
          secondsSeek = 0
        }
        player.seekTo(secondsSeek)
      }
    }
  };

  return(
      <>
        <ContainerPlayer>
          <PlayerCon>
            <ReactPlayer
                width={720}
                height={440}
                ref={ref}
                url={playerState.url}
                playing={playerState.playing}
                playbackRate={playerState.playbackRate}
                onProgress={handleProgress}

                onPlay={() => handlePlayer(true)}
                onPause={() => handlePlayer(false)}

                volume={playerState.volume}
                controls={playerState.controls}
                onSeek={e => console.log('onSeek', e)}
            />
          </PlayerCon>

          <ControllerBlock>
            <ItemTr>
              <ItemTh>Speed</ItemTh>
              <td>
                <Button onClick={handleSetPlaybackRate} value={1}>1x</Button>
                <Button onClick={handleSetPlaybackRate} value={1.5}>1.5x</Button>
                <Button onClick={handleSetPlaybackRate} value={2}>2x</Button>
                <Button onClick={handleSetPlaybackRate} value={3}>3x</Button>
              </td>
            </ItemTr>

            <ButtonRewind>
              <ItemTr>
                <Button onClick={() => PlusSeconds(-10)}>
                  <Icon name={'angle double left'}/>
                  -10 sec
                </Button>
                <Button onClick={() => PlusSeconds(-5)}>
                  <Icon name={'angle left'}/>
                  -5 sec
                </Button>

                <Button onClick={() => PlusSeconds(5)}>
                  +5 sec
                  <Icon name={'angle right'}/>
                </Button>
                <Button onClick={() => PlusSeconds(10)}>
                  + 10sec
                  <Icon name={'angle double right'}/>
                </Button>
              </ItemTr>
            </ButtonRewind>

          </ControllerBlock>

        </ContainerPlayer>
        <div>
          {tvDate.dateViews ?
              <TimeContainer className="container">
                <TimeSections>
                  <TimeSectionsOne>
                    <span>Начало: </span>
                    <span>{format(tvDate.dateStart, "dd MMM, HH:mm:ss")}</span>
                  </TimeSectionsOne>

                  <TimeSectionsOne>
                    <span>Выбранное время: </span>
                    <span>{format(tvDate.dateViews, "dd MMM, HH:mm:ss")}</span>
                  </TimeSectionsOne>

                  <TimeSectionsTwo>
                    <span>Конец: </span>
                    <span>{format(tvDate.dateEnd, "dd MMM, HH:mm:ss")}</span>
                  </TimeSectionsTwo>

                </TimeSections>
                <TimeRange
                    error={false}
                    ticksNumber={60}
                    step={1}
                    selectedInterval={[tvDate.dateViews]}
                    timelineInterval={[tvDate.dateStart, tvDate.dateEnd]}
                    onUpdateCallback={(e) => {}}
                    onChangeCallback={(e) => rewindTime(e)}
                />
              </TimeContainer>
              :
              <> </>
          }
          {YtSeekLine ?
              <SeekContainer>
                <Input
                  fluid
                  type='range' min={0} max={0.999999} step='any'
                  value={playerState.played}
                  onChange={(e) => seekPlayer(e.target.value)}
                />
              </SeekContainer>
              :
              <> </>
          }
        </div>
      </>
  )
};

export default TvPlayer;

TvPlayer.propTypes = {
  playerState: PropTypes.any,
  setPlayerState: PropTypes.any,
  tvDate: PropTypes.any,
  setTvDate: PropTypes.any,
  YtSeekLine: PropTypes.bool
};

TvPlayer.defaultProps = {
  playerState: {},
  setPlayerState: {},
  tvDate: {},
  setTvDate: {},
  YtSeekLine: false
};