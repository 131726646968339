import React from "react";
import TvSourceNameForm from "../../../containers/FormSourceName";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import InputItem from "../Form/Items/InputItem";
import DateTimeItem from "../Form/Items/DateTimeItem";


// TODO change form program and source
const TvArticleTextCreator = (props) => {
    const {
        articleError,

        titleValue,
        onChangeTitle,

        sourceName,
        programValue,
        onChangeProgram,

        OnChangeSourceName,

        searchValue,
        onChangeSearchValue,
        searchResults,
        searchLoad,
        onSelectSearch,

        startTime,
        endTime,
        OnChangeDateStart,
        OnChangeDateEnd,

        folderName,
        errorFolderName,
        OnChangeFolderName,

        type,
        OnChangeType,

        serverIp,
        errorServerIp,
        OnChangeServerIp,
    } = props;

    return (
        <>
            <Form>

                <TvSourceNameForm
                    sourceName={sourceName}
                    errorSourceName={articleError.sourceName}
                    OnChangeSourceName={OnChangeSourceName}

                    searchValue={searchValue}
                    onChangeSearchValue={onChangeSearchValue}

                    type={type}
                    OnChangeType={OnChangeType}
                    searchResults={searchResults}
                    searchLoad={searchLoad}
                    onSelectSearch={onSelectSearch}

                    folderName={folderName}
                    errorFolderName={errorFolderName}
                    OnChangeFolderName={OnChangeFolderName}

                    serverIp={serverIp}
                    errorServerIp={errorServerIp}
                    OnChangeServerIp={OnChangeServerIp}
                />

                <Form.Field>
                    <InputItem
                        ItemTitle="Заголовок"
                        ItemId="name"
                        ItemValue={titleValue}
                        ValidationItem={articleError.title}
                        onChangeFunction={onChangeTitle}
                    />
                </Form.Field>

                <Form.Field>
                    <DateTimeItem
                        ItemTitle="Дата старта"
                        ItemId="dateStart"
                        ItemValue={startTime}
                        error={articleError.startTime}
                        onChangeFunction={OnChangeDateStart}
                    />
                </Form.Field>

                <Form.Field>
                    <DateTimeItem
                        ItemTitle="Дата конца"
                        ItemId="dateEnd"
                        ItemValue={endTime}
                        error={articleError.endTime}
                        onChangeFunction={OnChangeDateEnd}
                    />
                </Form.Field>


                <Form.Field>
                    <InputItem
                        ItemTitle="Программа"
                        ItemId="program"
                        ItemValue={programValue}
                        ValidationItem={false}
                        onChangeFunction={onChangeProgram}
                    />
                </Form.Field>
            </Form>
        </>
    );
};

export default TvArticleTextCreator;