import React from 'react';
import {Button} from "semantic-ui-react";


const PagesButtons = (props) => {

  const { pages, onClickPage, dataLoaded } = props;

  return (
      <>
        { pages.prev ?
            <Button
                content='Предыдущая страница'
                icon='left arrow'
                labelPosition='left'
                onClick={() => onClickPage(pages.prev)}
                disabled={!dataLoaded}
            />
            :
            <> </>
        }
        {/* TODO append page num */}
        { pages.next ?
            <Button
                content='Следующая страница'
                icon='right arrow'
                labelPosition='right'
                onClick={() => onClickPage(pages.next)}
                disabled={!dataLoaded}
            />
            :
            <> </>
        }
      </>
  )
};
export default PagesButtons;