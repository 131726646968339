import React from "react";

import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {Map} from "react-lodash";
import TvArticleRow from "../TvPlayer/TvArticleTable";


const TableTvArticle = (props) => {

  const { tvArticle, errorsTvArticle, changeStateTvArticle, tvArticleProgramOptions, onAddItemProgram, DeleteCreatedArticle } = props;
  //TODO APPEND SOURCE NAME PROGRAM
  //TODO APPEND ONLY HUMAN PROCESS
  return(
    <>
      <Table celled padded>

        <Table.Header>
          <Table.Row>

            <Table.HeaderCell collapsing> </Table.HeaderCell>
            <Table.HeaderCell collapsing>№</Table.HeaderCell>
            <Table.HeaderCell>Время начала</Table.HeaderCell>
            <Table.HeaderCell>Время окончания</Table.HeaderCell>
            <Table.HeaderCell>Заголовок сюжета</Table.HeaderCell>
            <Table.HeaderCell>Программа</Table.HeaderCell>

          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Map
              collection={tvArticle.articles}
              iteratee={value => {
                return (
                    <TvArticleRow
                        key={value.id}
                        index={value.id}
                        num={value.number}
                        startTime={value.startTime}
                        endTime={value.endTime}
                        title={value.title}
                        disabled={value.disabled}
                        changeState={changeStateTvArticle}

                        errorTvArticle={errorsTvArticle.articlesErrors[value.id]}

                        optionSelectTvProgram={value.program ? value.program : null}
                        tvArticleProgramOptions={tvArticleProgramOptions.options[value.id] ? tvArticleProgramOptions.options[value.id] : false}
                        onAddItemProgram={onAddItemProgram}
                        DeleteCreatedArticle={DeleteCreatedArticle}
                    />
                )
              }}
          />
        </Table.Body>

      </Table>
    </>
  )

};
export default TableTvArticle;