import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";
import {Icon} from "semantic-ui-react";

//TODO change flex
const Column1 = styled.div`
  display: flex;
  align-items: center;
  flex: 10%;
  padding: 0 15px;
`;

const VerticalCenterStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableIconItem = (props) => {
  const {value} = props;
  return (
      <Column1>
        <VerticalCenterStyled>
          {value ?
              <Icon name={'plus circle'} color={'green'}/>
              :
              <Icon name={'minus circle'} color={'red'}/>
          }

        </VerticalCenterStyled>
      </Column1>
  )
};

export default TableIconItem;

TableIconItem.propTypes = {
  value: PropTypes.bool,
};

TableIconItem.defaultProps = {
  value: false
};
