import React from 'react';

import {Button, Icon} from 'semantic-ui-react'
import PropTypes from "prop-types";


const DeleteButton = (props) => {
  const {OnClick, text, icon} = props;
  return(
      <Button
          negative
          onClick={OnClick}
      >
        <Icon name={icon} />
        {text}
      </Button>
  )
};


export default DeleteButton;


DeleteButton.propTypes = {
  OnClick: PropTypes.any,
  text: PropTypes.string,
  icon: PropTypes.string
};

DeleteButton.default = {
  OnClick: () => {},
  text: '',
  icon: ''
};