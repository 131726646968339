import React, {useEffect, useState} from 'react';

import ServiceAPI from "../../../services";

import TvArticlesList from "./TableList";
import TableHeaderTvArticles from "../../common/Elements/TvArticles/TableHeaderTvArticles";
import TabCreate from "../TabPage";
import FooterPages from "../../common/Elements/FooterPages";
import {changeState} from "../../../helpersFunction/ChnageState";
import SelectedItems from "../../common/Elements/SelectedItems";
import {SelectAll} from "../../../helpersFunction/SelectAllId";
import {setPageList} from "../../../helpersFunction/SetPage";
import {selectCheckBox} from "../../../helpersFunction/CheckBoxDelete";
import {OrderFieldsList} from "../../../helpersFunction/OrderFields";


const TvArticlesListPage = (props) => {
  const optionsSearch = [
    { key: 'title', text: 'Заголовок', value: 'title' },
    { key: 'source_name', text: 'Название источника', value: 'source_name' },
  ];

  const [selectedItems, setSelectedItems] = useState({selectedItems:[]});
  const [tvArticles, setTvArticles] = useState([]);
  const [orderingList, setOrderingList] = useState([]);
  const [tvArticlesLoad, setTvArticlesLoad] = useState(false);
  const [pages, setPages] = useState({
    prev: '',
    next: ''
  });
  const [search, setSearch] = useState({
    searchField: 'title',
    searchValue: ''
  });
  const service = new ServiceAPI();

  //TODO duplicate
  const getListTvArticles = ( params ) => {
    setTvArticlesLoad(false);
    setTvArticles([]);

    service.getTvPlots(params ? params : null).then(resp => {

      if (resp.data) {
        setTvArticles(resp.data.results);
        setPageList(resp, pages, setPages);
        setTvArticlesLoad(true);
      }
    });
  };

  //TODO fix 2 request
  // useEffect(() => {
  //   getListTvArticles(null)
  // }, []);

  const ChangePage = (params) => {
    getListTvArticles(params)
  };

  // TODO duplicate
  const onClickCheckBox = (event) => {
    selectCheckBox(event, selectedItems, setSelectedItems);
  };

  const onClickDeleteList = () => {
    service.deleteTvArticleList(selectedItems).then(resp => {
      setSelectedItems({...selectedItems, selectedItems: []});
      getListTvArticles(null);
      setSearch({...search, searchValue: null})
    })
  };
  useEffect(()=>{
    if (search.searchValue || orderingList) {

      let filter = {};
      if (orderingList && orderingList.length > 0) {
        filter.ordering = orderingList
      }
      if (search.searchValue) {
        filter[search.searchField] = search.searchValue
      }

      getListTvArticles(filter ? filter : null)
    }
  }, [orderingList, search]);


  const handleOrderingList = (name) => {
    OrderFieldsList(orderingList, setOrderingList, name)
  };

  return (
      <>
        <TabCreate
            link={'/tv-articles'}
            showButtonPage={true}
            showButton={true}
            showCreateButton={true}
            showListButton={true}
            pages={pages}
            onClickPage={ChangePage}
            dataLoaded={tvArticlesLoad}
            optionsSearch={optionsSearch}
            OnChange={(e, {v})=> changeState("searchValue", e.target.value, search, setSearch)}
            SearchFieldChange={(e, v)=> changeState("searchField", v.value, search, setSearch)}
            onClickAllCheckBox={(e, v)=>SelectAll(tvArticles, selectedItems, setSelectedItems, "selectedItems", v.checked)}

            searchValue={search.searchValue}
        />

        <SelectedItems
            selectedItems={selectedItems.selectedItems}
            onClickCancel={()=> changeState("selectedItems", [], selectedItems, setSelectedItems)}
            onClickDeleteList={onClickDeleteList}
        />

        <TableHeaderTvArticles
            handleOrderingList={handleOrderingList}
            orderingList={orderingList}
        />

        <TvArticlesList
            tvArticles={tvArticles}
            tvArticlesLoad={tvArticlesLoad}
            onClickCheckBox={onClickCheckBox}
            selectedItems={selectedItems.selectedItems}
        />

        <FooterPages
            pages={pages}
            onClickPage={ChangePage}
            dataLoaded={tvArticlesLoad}
        />

      </>
  )
};

export default TvArticlesListPage;