import React from 'react';
import PropTypes from "prop-types";

import { Map } from 'react-lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import TableRowTvRange from "../../common/Elements/TvRange/TableTvRange";
import CheckBoxSelected from "../../common/Elements/Button/CheckBoxSelected";
import PageLoader from "../../common/Elements/Loader/PageLoader";


const ReportListContainerStyled = styled.div`
  overflow: auto;
`;

export const ContentWrapperStyled = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const ItemContainer = styled.div`
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

const TvRangeList = ( props ) => {

  const { TvRangeLoad, TvRanges, onClickCheckBox, selectedItems } = props;

  return (
      <ContentWrapperStyled>
        <PageLoader
            Load={TvRangeLoad}
        />
        <ReportListContainerStyled id="rangeListContainer">
          <Map
              collection={TvRanges}
              iteratee={value => {
                return (
                  <div key={value.id} >
                    <CheckBoxSelected
                        selectedItems={selectedItems.indexOf(value.id) !== -1}
                        onClickCheckBox={()=>onClickCheckBox(value.id)}
                    />

                    <ItemContainer>
                      <Link
                          key={value.id}
                          to={`/tv-range/${value.id}`}
                          exact={"true"}
                      >
                        <TableRowTvRange
                          tvProgramId={value.id}
                          tvRangeName={value.name}
                          tvRangeSourceName={value.sourceName}
                          tvRangeDate={value.createdDate}
                          tvRangeProcessed={value.processed}
                          tvRangePrime={value.prime}
                          tvRangeEnabled={value.enabled}
                        />
                      </Link>
                    </ItemContainer>

                  </div>
                );
              }}
          />
         </ReportListContainerStyled>
       </ContentWrapperStyled>
  )
};

export default TvRangeList;

TvRangeList.propTypes = {
  TvRangeLoad: PropTypes.bool,
  TvRanges: PropTypes.array,
  onClickCheckBox: PropTypes.any,
  selectedItems: PropTypes.array
};

TvRangeList.defaultProps = {
  TvRangeLoad: false,
  TvRanges: [],
  onClickCheckBox: ()=>{},
  selectedItems: []
};
