import React from 'react';
import { useParams } from 'react-router-dom';

import Header from "../containers/Header";
import SinglePage from "../containers/TvProgram/SinglePage";


const SingleTvProgram = () => {
  const { tvProgramId } = useParams();

  return (
      <>
        <Header />
        <SinglePage tvProgramId={tvProgramId}/>
      </>
  )
};
export default SingleTvProgram;