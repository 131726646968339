import React, {useEffect, useState} from 'react';

import TvPlayer from "../TvPlayer/TvPlayer";
import styled from "styled-components";
import YouTubeEditor from "../../common/Elements/YouTube/TextEditor";
import SaveButton from "../../common/Elements/Button/SaveButton";
import ServiceAPI from "../../../services";
import DimmerLoader from "../../common/Elements/Loader/DimmerLoader";
import {changeState} from "../../../helpersFunction/ChnageState";


const TvPlayerContainer = styled.div`
  display: flex;

  margin-left: 20px;
  margin-right: 20px;
`;


const TvPlayerDiv = styled.div`
  background: #ffffff;
  padding-bottom: 20px;
  flex: 30%;
  width: 49%;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 15px;

  border-color: rgb(235,237,244);
`;
const TextContainerDiv = styled.div`
  background: #ffffff;
  padding-bottom: 20px;
  // height: 800px;
  
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 15px;
  border-color: rgb(235,237,244);
  
  margin-left: 20px;
  margin-right: 20px;
  
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
`;

const SaveButtonContainer = styled.div`
   padding-top: 10px;
   padding-left: 20px;
`;

const YoutubeArticlePage = (props) => {

    const {YtArticleId} = props

    const service = new ServiceAPI();
    const [updateArticleFlag, setUpdateArticleFlag] = useState(false);

    const [playerState, setPlayerState] = useState({
        url: "",
        pip: false,
        playing: false,
        controls: true,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,

        //TODO append to seek
        playedSeconds: 0,
        playbackRate: 1.0,
        loop: false,
        SinglePage: true,
    });
    const [tvDate, setTvDate] = useState({
        dateStart: null,
        dateViews: null,
        dateEnd: null,

        hour: 0,
        minute: 0,
    });
    const [YtArticle, setYtArticle] = useState({
        id: 0,
        title: '',
        text: '',
        url: '',

        authorSubscribers: '',
        viewsCount: 0,
        description:'',
        durationTime:'',

        createdDate: null,
        publicationDate: null,

        prime: false,
        humanProcessed: false,
        youtubeParsed: false,

        ftpUrl: '',
        saveOnCrawler: false,
        youtubeId: '',
        authorName: '',
        channelUrl: '',
    });
    const UpdateYtArticleState = (data) => {
        setYtArticle({
            ...YtArticle,

            id: data.id,
            title: data.title,
            text: data.text,
            url: data.url,

            authorSubscribers: data.authorSubscribers,
            viewsCount: data.viewsCount,
            description: data.description,
            durationTime: data.durationTime,

            createdDate: data.createdDate,
            publicationDate: data.publicationDate,

            prime: data.prime,
            humanProcessed: data.humanProcessed,
            youtubeParsed: data.youtubeParsed,

            ftpUrl: data.ftpUrl,

            saveOnCrawler: data.saveOnCrawler,
            youtubeId: data.youtubeId,
            authorName: data.authorName,
            authorUrl: data.authorUrl,
        });
    };

    const updateArticle = () => {
        setUpdateArticleFlag(true);
        service.updateYtArticle(YtArticle, YtArticleId).then(resp => {
            let responseData = resp.data;
            console.log(responseData)
            UpdateYtArticleState(responseData);

            setUpdateArticleFlag(false);
        })
    };

    useEffect(() => {
        service.getYTArticleById(YtArticleId).then(resp => {
            if (resp.status !== 200){
                return;
            }
            console.log(resp.data)
            let responseData = resp.data;
            UpdateYtArticleState(responseData);

            setPlayerState({...playerState, url: responseData.ftpUrl});
        })
    },[]);

    return(
        <>
            <DimmerLoader
                textOfLoader="Сохранение ..."
                active={updateArticleFlag}
            />

            <TvPlayerContainer >
                <TvPlayerDiv>
                    <TvPlayer
                        playerState={playerState}
                        setPlayerState={setPlayerState}
                        tvDate={tvDate}
                        setTvDate={setTvDate}
                        YtSeekLine={true}
                    />
                </TvPlayerDiv>
            </TvPlayerContainer>

            <TextContainerDiv>
                <YouTubeEditor
                    titleValue={YtArticle.title}
                    onChangeTitle={(e) => changeState("title", e.target.value, YtArticle, setYtArticle)}

                    textValue={YtArticle.text}
                    onChangeText={(e) => changeState("text", e.target.value, YtArticle, setYtArticle)}

                    description={YtArticle.description}
                    onChangeDescription={(e) => changeState("description", e.target.value, YtArticle, setYtArticle)}

                    authorSubscribers={YtArticle.authorSubscribers}
                    onChangeAuthorSubscribers={(e) => changeState("authorSubscribers", e.target.value, YtArticle, setYtArticle)}

                    viewsCount={YtArticle.viewsCount}
                    onChangeViewsCount={(e) => changeState("viewsCount", e.target.value, YtArticle, setYtArticle)}

                    durationTime={YtArticle.durationTime}

                    authorUrl={YtArticle.authorUrl}
                    authorName={YtArticle.authorName}
                    createdDate={YtArticle.createdDate}
                    publicationDate={YtArticle.publicationDate}
                    url={YtArticle.url}

                />

                <SaveButtonContainer>
                    <SaveButton
                        disabled={false}
                        OnClick={updateArticle}
                        text={'Сохранить'}
                    />
                </SaveButtonContainer>
            </TextContainerDiv>
        </>
    )

}


export default YoutubeArticlePage;