import React from 'react';

import logo from '../../images/logo.png';

import Navigation from "../common/Navigation/Nvigation";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import {Button, Icon} from "semantic-ui-react";

const ContainerBackGround = styled.div`
  background: #d2eaff24;
`;

const HeaderWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  max-height: 52px;
  min-height: 52px;
  background: #afafafb0;
  border-radius: 0 0 10px 10px;
;
`;

const LogoImage = styled.img`
  position: relative;
  margin-top: 2%;
  width: 190px;
  height: 40px;
  padding-left: 15px;
`;

const ButtonDiv = styled.div`
  margin: auto 0 auto auto;
  padding-right: 15px;
`;


const Header = () => {

  //TODO append server logout
  const ExitFunction = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };
  return (
      <>
          <ContainerBackGround>
              <HeaderWrapperStyled>
                  <Link to="/">
                      <LogoImage src={logo} />
                  </Link>

                  <ButtonDiv>
                      <Button onClick={ExitFunction}>
                          <Icon name='sign-out' />
                          Выход
                      </Button>
                  </ButtonDiv>

              </HeaderWrapperStyled>
          </ContainerBackGround>


        <Navigation />
      </>
  )
};

export default Header;