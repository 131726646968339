import React from 'react';
import {Map} from "react-lodash";

import TimeList from "../../common/Elements/Form/TimeList";

const TimeListMap = (props) => {
  const {
    collection,
    errors,
    ChangeTimeListItem,
    optionsWeekdays,
    disabledDeleteButton,
    DeleteTimeListItem
  } = props;

  return(
    <Map
      collection={collection}
      iteratee={value => {
        return (
          <TimeList
              key={value.id}
              Value={value}
              ValidationItem={errors}
              changeFun={ChangeTimeListItem}
              optionsWeekdays={optionsWeekdays}
              disabledDeleteButton={disabledDeleteButton}
              DeleteTimeListItem={DeleteTimeListItem}
          />
        )
      }}
    />
  )
};

export default TimeListMap;
