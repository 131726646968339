import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";

import ServiceAPI from '../../../services';

import DimmerLoader from "../../common/Elements/Loader/DimmerLoader";
import {changeState} from "../../../helpersFunction/ChnageState";
import TvRangeForm from "./TvRangeForm";
import {AddTimeList, changeTimeList, DeleteTimeListItem} from "../../../helpersFunction/TimeListFunc";
import {
  getWeekdaysOption,
  searchSite,
  selectSearchResult,
  validateTvRage
} from "../../../helpersFunction/TvRange";
import {throttle} from "lodash";
import UpdateButton from "../../common/Elements/Button/UpdateButton";


const UpdateButtonDiv = styled.div`
  padding-top: 15px;
  width: 95%;
  margin-left: auto;
  padding-bottom: 15px;
`;

const SinglePage =  props  => {
  const { tvRangeId } = props;

  // TODO a lot of useState
  const [errors, setErrors] = useState({
    validForm: false,
    name: '',
    sourceName: '',
    timeList: '',

    weekdays: '',

    folderName: '',
    serverIp: '',
  });

  const [tvRange, setTvRange] = useState({
    id: 0,
    name: '',

    sourceId: '',
    sourceName: '',
    timeList: [],
    createdDate: '',

    folderName: '',
    serverIp: '',
    type: '',

    processed: false,
    prime: true,
    enabled: true,
  });

  const [updateTvRangeArticle, setUpdateTvRangeArticle] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [optionsWeekdays, setOptionsWeekday] = useState([{"key": "-", "value": "-", "text": "-"}]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoad, setSearchLoad] = useState(false);
  const [selectSearch, setSelectSearch] = useState({});

  const service = new ServiceAPI();

  const ChangeTimeListItem = (key, value, keyField) => {
    changeTimeList(tvRange, setTvRange, keyField, key, value);
  };

  // Get option to select weekday
  useEffect(()=>{
    getWeekdaysOption(setOptionsWeekday);
  },[]);

  // Update state data of tvRange
  const updateTvRange = (data) => {
    setTvRange({...tvRange,
      enabled: data.enabled,
      folderName: data.folderName,
      prime: data.prime,
      processed: data.processed,
      serverIp: data.serverIp,
      sourceId: data.sourceId,
      type: data.type,
      sourceName: data.sourceName,
      timeList: data.timeList,
      name: data.name,
      id: data.id,
    })
  };

  //Get obj from server
  useEffect(()=>{
    service.getTvRange(tvRangeId).then(resp => {
      let data = resp.data;
      if (data){
        updateTvRange(data);
      }
    });
  },[]);

  const throttledSearch = useRef(throttle(
      (setSearchLoad, setSearchResults, searchValue) =>
          searchSite(setSearchLoad, setSearchResults, searchValue),
      700)
  );
  //Search field of source from crawler
  useEffect(()=>{
    throttledSearch.current(setSearchLoad, setSearchResults, searchValue);
  }, [searchValue]);

  useEffect(()=>{
    selectSearchResult(setSearchValue, setTvRange, tvRange, selectSearch);
  }, [selectSearch]);

  useEffect(() => {
    validateTvRage(tvRange, setErrors, errors)
  },[tvRange]);

  const UpdateTvRange = () => {
    setUpdateTvRangeArticle(true);
    service.updateTvRange(tvRange, tvRangeId).then(resp => {
      let data = resp.data;
      if (data){
       updateTvRange(data);
      }
      setUpdateTvRangeArticle(false);
    })
  };
  const DeleteTimeList = (id) => {
    DeleteTimeListItem(id, tvRange, setTvRange);
  };

  const DeleteAllTimeListItems = () => {
    setTvRange({...tvRange, timeList: []})
  };

  // View page
  return (
    <>
      {/* Menu sections */}
      <DimmerLoader
          textOfLoader="Сохранение ..."
          active={updateTvRangeArticle}
      />
      <TvRangeForm
          tvRange={tvRange}
          errorsTvRange={errors}

          OnChangeType={e => changeState("type", e.target.value, tvRange, setTvRange)}
          OnChangeName={e => changeState("name",e.target.value, tvRange, setTvRange)}
          OnChangeSourceName={e => changeState("sourceName",e.target.value, tvRange, setTvRange)}
          OnChangeFolderName={e => changeState("folderName",e.target.value, tvRange, setTvRange)}
          OnChangeServerIp={e => changeState("serverIp",e.target.value, tvRange, setTvRange)}
          OnChangeProcessed={(e, v) => changeState("processed", v.checked, tvRange, setTvRange)}
          OnChangePrime={(e, v) => changeState("prime", v.checked, tvRange, setTvRange)}
          OnChangeEnabled={(e, v) => changeState("enabled", v.checked, tvRange, setTvRange)}

          AddTimeListButton={()=>AddTimeList(tvRange, setTvRange)}

          ChangeTimeListItem={ChangeTimeListItem}
          optionsWeekdays={optionsWeekdays}

          searchValue={searchValue}
          onChangeSearchValue={(e, {value}) => setSearchValue(value)}
          searchResults={searchResults}
          searchLoad={searchLoad}
          onSelectSearch={(e, data)=>setSelectSearch(data.result)}

          blockDelete={false}

          disabledDeleteButton={false}
          DeleteTimeListItem={DeleteTimeList}
          DeleteTimeList={DeleteAllTimeListItems}
      />
      <UpdateButtonDiv>
        <UpdateButton
            disabled={errors.validForm}
            OnClick={UpdateTvRange}
            text={"Обновить запись"}
        />
      </UpdateButtonDiv>

    </>
  )
};
export default SinglePage;
