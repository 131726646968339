import React from 'react';
import Header from '../containers/Header';
import YoutubeArticlePage from "../containers/YoutubeArticles/SinglePageArticle";
import {useParams} from "react-router-dom";


const YouTubeArticleSinglePage = () => {
    const { YtArticle } = useParams();
    return (
        <>
            <Header />

            <YoutubeArticlePage YtArticleId={YtArticle} />
        </>
    )
};
export default YouTubeArticleSinglePage;