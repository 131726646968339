import React from 'react';
import { useParams } from 'react-router-dom';

import Header from "../containers/Header";
import SinglePage from "../containers/TvRange/SinglePage";


const SingleTvRange = () => {
  const { tvRangeId } = useParams();

  return (
      <>
        <Header />
        <SinglePage tvRangeId={tvRangeId}/>
      </>
  )
};
export default SingleTvRange;