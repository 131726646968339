import React from "react";

import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import styled from "styled-components";
import {Checkbox, Input} from "semantic-ui-react";

const LabelContainer = styled.div`
    padding-top: 10px;
    width: 12%;
    text-align: center;
    box-sizing: inherit;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
`;

const LabelText = styled.label`
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 22px;
    line-height: 1.4285em;
    color: rgba(0,0,0,.87);
`;
const SmallLabelText = styled.label`
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 18px;
    line-height: 1.4285em;
    margin-right: 5px;
    padding-top: 5px;
    color: rgba(0,0,0,.87);
`;
const TextAreaContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #cba3a8;
`;

const InputContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 1px solid #cba3a8;
  padding-bottom: 20px;
`;

const DiVcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
`;
const MainVcon = styled.div`
  width: 100%;
  padding-top: 20px;
  
`;
const Gh = styled.div`
  display: flex;
  padding-right: 40px;
  width: 400px;
`
const Ghh = styled.label`
  padding-right: 5%;
`
const LineEnd = styled.div`
  border-bottom: 1px solid #cba3a8;
  padding-bottom: 20px;
`

const  StyledLink = {float: "right", fontWeight: 'bold', fontSize: '14px'}

const YouTubeEditor = props => {

    const {
        onChangeTitle,
        titleValue,

        textValue,
        onChangeText,

        description,
        onChangeDescription,

        authorSubscribers,
        onChangeAuthorSubscribers,

        viewsCount,
        onChangeViewsCount,

        durationTime,

        authorUrl,
        authorName,
        createdDate,
        publicationDate,
        url
    } = props;

    return(
      <>
          <LabelContainer>
              <LabelText>
                  Заголовок
              </LabelText>
          </LabelContainer>

          <InputContainer>
              <Input
                  // error={articleError.title}
                  style={{ minWidth: '100%' }}

                  placeholder={"Заголовок"}
                  onChange={onChangeTitle}
                  value={titleValue}
              />
          </InputContainer>

          <LabelContainer>
              <LabelText>
                  Текст
              </LabelText>
          </LabelContainer>

          <TextAreaContainer>
              <TextArea
                  placeholder='Распознанный текст'
                  style={{ minHeight: 100, maxWidth:'100%', minWidth:'100%', maxHeight: '350px' }}
                  value={textValue}
                  onChange={onChangeText}
              />
          </TextAreaContainer>

          <LabelContainer>
              <LabelText>
                  Описание
              </LabelText>
          </LabelContainer>

          <TextAreaContainer>
              <TextArea
                  placeholder='Описание'
                  style={{ minHeight: 100, maxWidth:'100%', minWidth:'100%', maxHeight: '250px' }}
                  value={description}
                  onChange={onChangeDescription}
              />
          </TextAreaContainer>

          <MainVcon>
              <DiVcon>
                  <Gh>
                      <SmallLabelText>
                          attendance:
                      </SmallLabelText>
                      <Input
                          type="number"
                          style={{float: "right",paddingLeft: "5px"}}
                          value={authorSubscribers}
                          onChange={onChangeAuthorSubscribers}
                      />
                  </Gh>

                  <Gh>
                      <SmallLabelText>
                          views_count:
                      </SmallLabelText>
                      <Input
                          type="number"
                          style={{float: "right",paddingLeft: "5px"}}
                          value={viewsCount}
                          onChange={onChangeViewsCount}
                      />
                  </Gh>

                  <Gh>
                      <SmallLabelText>
                          duration_time:
                      </SmallLabelText>
                      <Input
                          style={{float: "right",paddingLeft: "5px"}}
                          value={durationTime}
                          disabled={true}
                      />
                  </Gh>

              </DiVcon>
          </MainVcon>

          <MainVcon>
              <DiVcon>
                  <Gh>
                      <SmallLabelText>
                          url:
                      </SmallLabelText>
                      <a
                          style={
                              {
                                  float: "right",
                                  fontWeight: 'bold',
                                  fontSize: '14px',
                                  paddingLeft: "10px",
                                  paddingTop:"8px"}
                          }
                          href={url}
                          target="_blank"
                      > VIDEO LINK </a>
                  </Gh>
                  <Gh>
                      <SmallLabelText>
                          channel_url:
                      </SmallLabelText>
                      <a
                          style={
                              {
                                  float: "right",
                                  fontWeight: 'bold',
                                  fontSize: '14px',
                                  paddingLeft: "10px",
                                  paddingTop:"8px"}
                          }
                          href={authorUrl}
                          target="_blank"
                      >LINK TO CHANNEL</a>
                  </Gh>
                  <Gh>
                      <SmallLabelText>
                          channel_name:
                      </SmallLabelText>
                      <Input
                          style={{float: "right", fontWeight: 'bold', fontSize: '14px',}}
                          disabled={true}
                          value={authorName}
                      />
                  </Gh>
              </DiVcon>
          </MainVcon>

          <MainVcon>
              <DiVcon>
                  <Gh>
                      <SmallLabelText>
                          createdDate:
                      </SmallLabelText>
                      <Input
                          style={{float: "right", paddingLeft: "5px"}}
                          disabled={true}
                          value={createdDate}
                      />
                  </Gh>
                  <Gh>
                      <SmallLabelText>
                          publicationDate:
                      </SmallLabelText>
                      <Input
                          style={{float: "right", paddingLeft: "5px"}}
                          disabled={true}
                          value={publicationDate}
                      />
                  </Gh>
              </DiVcon>
          </MainVcon>
        <LineEnd/>
      </>
    )

};

export default YouTubeEditor;
