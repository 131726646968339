import React, {useEffect, useState} from 'react';

import ServiceAPI from "../../../services";

import TvProgramList from "./TableList";
import TableHeaderTvProgram from "../../common/Elements/TvProgram/TableHeaderTvProgram";
import TabCreate from "../TabPage";
import FooterPages from "../../common/Elements/FooterPages";
import {parseQuery} from "../../../helpersFunction/parseQuery";
import {changeState} from "../../../helpersFunction/ChnageState";
import SelectedItems from "../../common/Elements/SelectedItems";
import {SelectAll} from "../../../helpersFunction/SelectAllId";
import {OrderFieldsList} from "../../../helpersFunction/OrderFields";


const TvProgramListPage = (props) => {

  const optionsSearch = [
    { key: 'source', text: 'Название источника', value: 'source_name' },
    { key: 'name', text: 'Название', value: 'name' },
  ];

  const [orderingList, setOrderingList] = useState([]);
  const [selectedItems, setSelectedItems] = useState({selectedItems:[]});
  const [tvPrograms, setTvPrograms] = useState([]);
  const [tvProgramLoad, setTvProgramLoad] = useState(false);
  const [pages, setPages] = useState({
    prev: '',
    next: ''
  });
  const [search, setSearch] = useState({
    searchField: 'name',
    searchValue: ''
  });
  const service = new ServiceAPI();

  const getListProgram = ( params ) => {
    setTvProgramLoad(false);
    setTvPrograms([]);

    let nextPage = '';
    let prevPage = '';

    service.getTvPrograms(params ? params : null).then(resp => {
      console.log(resp);
      if (resp.data){
        setTvPrograms(resp.data.results);
        setTvProgramLoad(true);

        if(resp.data.next){
          nextPage = parseQuery(resp.data.next);
        }

        if(resp.data.previous){
          prevPage = parseQuery(resp.data.previous);
        }
      }

      setPages({...pages,
        prev: prevPage,
        next: nextPage
      })

    });
  };
  // TODO fix 2 request
  //   useEffect(() => {
  //     getListProgram(null)
  //   }, []);

  const ChangePage = (params) => {
    getListProgram(params)
  };

  useEffect(() => {
    if (search.searchValue || orderingList) {

      let filter = {};
      if (orderingList && orderingList.length > 0) {
        filter.ordering = orderingList
      }

      if (search.searchValue) {
        filter[search.searchField] = search.searchValue
      }

      getListProgram(filter ? filter : null);
    }
  }, [orderingList, search]);

  const handleOrderingList = (name) => {
    OrderFieldsList(orderingList, setOrderingList, name)
  };

  const onClickCheckBox = (e) => {
    let idItems = selectedItems.selectedItems;
    let index = idItems.indexOf(e);
    if (index === -1){
      idItems.push(e)
    }else{
      idItems.splice(index, 1)
    }
    setSelectedItems({...selectedItems, selectedItems: idItems})
  };

  const onClickDeleteList = () => {
    service.deleteTvProgramList(selectedItems).then(resp => {
      setSelectedItems({...selectedItems, selectedItems: []});
      getListProgram(null);
      setSearch({...search, searchValue:''})
    })
  };

  return (
      <>
        <TabCreate
            link={'/tv-program'}
            showButtonPage={true}
            showButton={true}
            showCreateButton={true}
            showListButton={true}
            pages={pages}
            onClickPage={ChangePage}
            dataLoaded={tvProgramLoad}
            optionsSearch={optionsSearch}
            OnChange={(e, {v})=> changeState("searchValue", e.target.value, search, setSearch)}
            SearchFieldChange={(e, v)=> changeState("searchField", v.value, search, setSearch)}
            onClickAllCheckBox={(e, v)=> SelectAll(tvPrograms, selectedItems, setSelectedItems, "selectedItems", v.checked)}

            searchValue={search.searchValue}
        />

        <SelectedItems
            selectedItems={selectedItems.selectedItems}
            onClickCancel={()=> changeState("selectedItems", [], selectedItems, setSelectedItems)}
            onClickDeleteList={onClickDeleteList}
        />

        <TableHeaderTvProgram
            handleOrderingList={handleOrderingList}
            orderingList={orderingList}
        />

        <TvProgramList
            tvPrograms={tvPrograms}
            tvProgramLoad={tvProgramLoad}
            selectedItems={selectedItems.selectedItems}
            onClickCheckBox={onClickCheckBox}
        />

        <FooterPages
          pages={pages}
          onClickPage={ChangePage}
          dataLoaded={tvProgramLoad}
        />
      </>
  )
};

export default TvProgramListPage;