
export function validateTvProgram(tvProgram, setErrors, errors) {
  // VALIDATION FORM
  let name = false;
  let sourceName = false;
  let timeList = {};
  let validForm = true;

  if (!tvProgram.name || tvProgram.name.length < 2){
    validForm = false;
    name = 'Значчение должно быть больше 2!'
  }

  if (!tvProgram.sourceName || tvProgram.sourceName.length < 2){
    validForm = false;
    sourceName = 'Значчение должно быть больше 2!'
  }

  if (tvProgram.timeList.length > 0){

    tvProgram.timeList.forEach((item)=>{
      timeList[item.id] = {
        error: false,
        weekdays: false,
        Time: false,
      };

      if(item.weekdays === '-'){
        validForm = false;
        timeList[item.id].weekdays = "Поле должно быть заполненно!";
        timeList[item.id].error = true;

        return;
      }

      if(item.startTime === "" || item.endTime === ""){
        validForm = false;
        timeList[item.id].Time = "Поле должно быть заполненно!";
        timeList[item.id].error = true;

        return;
      }
      if (item.startTime === item.endTime){
        validForm = false;
        timeList[item.id].Time = "Время начала не может === время конца!";
        timeList[item.id].error = true;

        return;
      }
      if (item.startTime > item.endTime){
        validForm = false;
        timeList[item.id].Time = "Время начала не может быть больше время конца!";
        timeList[item.id].error = true;

        return;
      }
    });
  }

  setErrors({...errors,
    validForm: validForm,
    name: name,
    sourceName: sourceName,
    timeList: timeList,
  })
}

export function setTvProgramSelect(tvProgram, selectSearch, setTvProgram) {
  if(tvProgram.sourceName !== selectSearch.name){
    setTvProgram({...tvProgram, sourceId: ''})
  }else{
    setTvProgram({...tvProgram, sourceId: selectSearch.id})
  }
}

export function selectSearchResult(setSearchValue, setTvProgram, tvProgram, selectSearch) {
  setSearchValue(selectSearch.title);
  setTvProgram({...tvProgram,
    sourceId: selectSearch.id,
    sourceName: selectSearch.name,
  })
}