
export function OrderFieldsList(orderingList, setOrderingList, name) {
  // if this is first render
  if (orderingList === null) {
    setOrderingList([name]);
  }
  // if name include '-' then delete from array
  else if (orderingList.includes(`-${name}`)) {
    const index = orderingList.indexOf(`-${name}`);
    const newlist = [...orderingList];
    newlist.splice(index, 1);
    setOrderingList(newlist);
  }
  // if name already exists in array then add '-' to name
  else if (orderingList.includes(name)) {
    const index = orderingList.indexOf(name);
    const newlist = [...orderingList];
    newlist[index] = `-${name}`;
    setOrderingList(newlist);
  }
  // if name is not in array then add name to array
  else {
    setOrderingList(orderingList.concat([`${name}`]));
  }
}
