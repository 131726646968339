import jwt_decode from 'jwt-decode';

const GET = 'GET';
const POST = 'POST';
const PUT = 'PUT';
const PATCH = 'PATCH';
const DELETE = 'DELETE';
const CONTENT_TYPE_JSON = { 'Content-Type': 'application/json' };

export const getInfoFromToken = key => {
  try {
    const token = localStorage.getItem('token');

    const decodedToken = jwt_decode(token);
    return decodedToken[key];
  } catch (error) {
    return {};
  }
};

function isTokenExp() {
  const exp = getInfoFromToken('exp');
  const date = new Date();
  const timestamp = Math.floor(date.getTime() / 1000);
  return exp < timestamp;
}

async function request(
  url,
  params,
  method = GET,
  headers = CONTENT_TYPE_JSON,
  tokenPre = 'Bearer'
) {
    const token = localStorage.getItem('token');
    if (token) {
      headers.Authorization = `${tokenPre} ${token.replace(/^"(.*)"$/, '$1')}`;
    }
    const options = {
      method,
      headers,
    };

  // if params exists and method is GET, add query string to url
  // otherwise, just add params as a "body" property to the options object
  if (params) {
    if (method === GET) {
      url += `?${objectToQueryString(params)}`;
    } else if (params instanceof FormData) {
      options.body = params;
    } else {
      options.body = JSON.stringify(params); // body should match Content-Type in headers option
    }
  }
  let status = null;
  let data = null;
  let body = null;
  let reader = null;
  let header = null;

  try {
    const response = await fetch(url, options);
    if (response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        status = response.status;
        data = response.statusText;
        body = response.body;
      }
      status = response.status;
      data = result;
    }

    // if ([400, 403, 500].includes(response.status)) {
    //   let result;
    //   try {
    //     result = await response.json();
    //   } catch (e) {
    //     status = response.status;
    //     data = response.statusText;
    //     body = response.body;
    //   }
    //   status = response.status;
    //   data = result;
    // }

    if (response.status === 403) {
      if (window.location.pathname === "/tv-articles") {
          localStorage.removeItem('token');
      } else {
          alert("Access forbidden");
      }
      window.location.href = '/';
    }
  } catch (e) {
    console.log(`FETCH ERROR: ${e}`);
  }
  console.log({ status, data, body });
  return { status, data, body };
}

function objectToQueryString(obj) {
  return Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&');
}

export default class ServiceAPI {
  constructor() {
    this.server = process.env.REACT_APP_SERVER;
    this.api = process.env.REACT_APP_API;
    this.apiUrl = `${this.server}${this.api}`;
  }

  async _refresh() {
    localStorage.removeItem('token');
    return await request(
      `${this.apiUrl}/token_refresh/`,
      {
        refresh: JSON.parse(localStorage.getItem('tokenRefresh'))
      },
      POST
    );
  }

  async _request(url, params, METHOD, headers) {
    // if (isTokenExp()) {
    //   const token = await this._refresh();
    //   console.log('TOKEN IS REFRESH');
    //
    //   try {
    //     localStorage.setItem('token', JSON.stringify(token.data.access));
    //   } catch (e) {
    //     localStorage.clear();
    //   }
    // }

    return await request(url, params, METHOD, headers);
  }

  //TV program route
  async getTvPrograms(params) {
    return await this._request(
      `${this.apiUrl}/tv-program/`,
        params,
      GET
    );
  }

  async getTvProgram(tvProgramId) {
    return await this._request(
        `${this.apiUrl}/tv-program/${tvProgramId}/`,
        null,
        GET
    );
  }

  async createTvProgram(params) {
    return await this._request(
      `${this.apiUrl}/tv-program/`,
      params,
      POST,
    );
  }

  async updateTvProgram(params, tvProgramId) {
    return await this._request(
      `${this.apiUrl}/tv-program/${tvProgramId}/`,
      params,
      PATCH,
    );
  }

  async deleteTvProgram(tvProgramId) {
    return await this._request(
        `${this.apiUrl}/tv-program/${tvProgramId}/`,
        null,
        DELETE
    );
  }

  async deleteTvProgramList(params){
    return await this._request(
        `${this.apiUrl}/tv-program/delete_list/`,
        params,
        DELETE,
    )
  };

  //TV plot program
  async getTvPlots(params) {
    return await this._request(
      `${this.apiUrl}/tv-plot/`,
        params,
      GET
    );
  }

  async getTvPlot(tvPlotId) {
    return await this._request(
        `${this.apiUrl}/tv-plot/${tvPlotId}/`,
        null,
        GET
    );
  }
  async deleteTvArticleList(params){
    return await this._request(
        `${this.apiUrl}/tv-plot/delete_list/`,
        params,
        DELETE,
    )
  };
  async createTvPlot(params) {
    return await this._request(
        `${this.apiUrl}/tv-plot/`,
      params,
      POST
    );
  }

  async createTvPlotList(params) {
    return await this._request(
        `${this.apiUrl}/tv-plot/create_list/`,
        params,
        POST
    );
  }

  async updateTvArticle(params, tvPlotId) {
    return await this._request(
        `${this.apiUrl}/tv-plot/${tvPlotId}/`,
        params,
        PATCH
    );
  }

  async deleteTvPlot(tvPlotId) {
    return await this._request(
        `${this.apiUrl}/tv-program/${tvPlotId}/`,
        null,
        DELETE
    );
  }

  //TV range route
  async getTvRanges(params) {
    return await this._request(
        `${this.apiUrl}/tv-range/`,
        params,
        GET
    );
  }

  async deleteTvRangeList(params){
    return await this._request(
        `${this.apiUrl}/tv-range/delete_list/`,
        params,
        DELETE,
    )
  };
  async getTvRange(tvRageId) {
    return await this._request(
        `${this.apiUrl}/tv-range/${tvRageId}/`,
        null,
        GET
    );
  }

  async createTvRange(params) {
    return await this._request(
        `${this.apiUrl}/tv-range/`,
        params,
        POST
    );
  }

  async updateTvRange(params, tvRageId) {
    return await this._request(
        `${this.apiUrl}/tv-range/${tvRageId}/`,
        params,
        PATCH
    );
  }

  async deleteTvRange(tvRageId) {
    return await this._request(
        `${this.apiUrl}/tv-program/${tvRageId}/`,
        null,
        DELETE
    );
  }

  async sendAuth(params) {
    return await this._request(
        `${this.server}/login/`,
        params,
        POST
    );
  }
  async getWeekdaysOptions() {
    return await this._request(
        `${this.apiUrl}/get-day-options/`,
        null,
        GET
    );
  }
  async searchSiteCrawler(params) {
    return await this._request(
        `${this.apiUrl}/search-site/`,
        params,
        GET
    );
  }

  async getTvRageToCut(params) {
    return await this._request(
        `${this.apiUrl}/tv-cut/`,
        params,
        GET
    );
  }
  async getTvRangeCut(tvRageId) {
    return await this._request(
        `${this.apiUrl}/tv-cut/${tvRageId}/`,
        null,
        GET
    );
  }
  async getTvRangeCutDeleteList(params) {
    return await this._request(
        `${this.apiUrl}/tv-cut/delete_list/`,
        params,
        DELETE
    );
  }
  async getArticlesTvRangeCut(tvRageId) {
    return await this._request(
        `${this.apiUrl}/tv-cut/${tvRageId}/get_article_tv_range_cut/`,
        null,
        GET
    );
  }
  async getProgramByWeekDay(params) {
    return await this._request(
        `${this.apiUrl}/tv-program/get_program_by_week_day/`,
        params,
        GET
    );
  }
  //YOUTUBE
  async getYTArticles(params) {
    return await this._request(
        `${this.apiUrl}/youtube-articles/`,
        params,
        GET
    );
  }
  async createYTArticles(params) {
    return await this._request(
        `${this.apiUrl}/youtube-articles/`,
        params,
        POST
    );
  }
  async deleteYTArticles(params) {
    return await this._request(
        `${this.apiUrl}/youtube-articles/delete_list/`,
        params,
        DELETE
    );
  }
  async getYTArticleById(YouTubeId) {
    return await this._request(
        `${this.apiUrl}/youtube-articles/${YouTubeId}`,
        null,
        GET
    );
  }
  async updateYtArticle(params, YouTubeId) {
    return await this._request(
        `${this.apiUrl}/youtube-articles/${YouTubeId}/`,
        params,
        PATCH
    );
  }
  async getBillingData(params) {
    return await this._request(
        `${this.apiUrl}/billing/`,
        params,
        GET
    );
  }
}
