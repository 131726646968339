import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react'
import {SettingItemStyled} from "./styled";

const ItemTitleStyled = styled.div`
  text-transform: uppercase;
  flex: 25%;
`;

const ItemValueStyled = styled.div`
  flex:65%;
`;

const InputItem = props => {
  const { ItemTitle, ItemValue, onChangeFunction, ValidationItem, disabled } = props;

  return (
      <SettingItemStyled>

        <ItemTitleStyled>
          <label>
            {ItemTitle} :
          </label>
        </ItemTitleStyled>

        <ItemValueStyled>
          <Form.Input
              disabled={disabled}
              error={ValidationItem}
              placeholder={ItemTitle}
              onChange={onChangeFunction}
              value={ItemValue || ""}
          />
        </ItemValueStyled>

      </SettingItemStyled>
  );
};

export default InputItem;

InputItem.propTypes = {
  ItemTitle: PropTypes.string,
  ItemValue: PropTypes.any,
  onChangeFunction: PropTypes.any,
  ValidationItem: PropTypes.bool|| PropTypes.string ,
  disabled: PropTypes.bool,
};

InputItem.default = {
  ItemTitle: '-',
  ItemValue: null,
  onChangeFunction: null,
  ValidationItem: false,
  disabled: true,
};
