import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react'
import {SettingItemStyled} from "./styled";


export const ItemTitleStyled = styled.div`
  text-transform: uppercase;
  flex: 30%;
`;

export const ItemValueStyled = styled.div`
  flex:60%;
  max-wight: 10px;
`;


const TimeInputItem = props => {
  const { ItemTitle, ItemValue, onChangeFunction, error } = props;

  return (
      <SettingItemStyled>

        <ItemTitleStyled>
          <label>
            {ItemTitle} :
          </label>
        </ItemTitleStyled>

        <ItemValueStyled>
          <Form.Input
              error={error}
              type='time'
              value={ItemValue}
              onChange={onChangeFunction}
          />
        </ItemValueStyled>

      </SettingItemStyled>
  );
};

export default TimeInputItem;

TimeInputItem.propTypes = {
  ItemTitle: PropTypes.string,
  ItemValue: PropTypes.string || PropTypes.bool,
  onChangeFunction: PropTypes.any,
};

TimeInputItem.default = {
  ItemTitle: '-',
  ItemValue: false,
  onChangeFunction: null
};
