import React from 'react';
import Header from '../containers/Header';
import TvProgramListPage from "../containers/TvProgram/LIstPage";

const TvProgram = () => {
  return (
      <>
        <Header />

        <TvProgramListPage />
      </>
  )
};
export default TvProgram;