import React from 'react';
import styled from 'styled-components';
import BoolItem from "./Items/BoolItem";
import SelectItem from "./Items/SelectItem";
import TimeInputItem from "./Items/TimeInputItem";
import DeleteItemButton from "../Button/DeleteItemButton";

export const TimeListStyled = styled.div`
  background: #f5f5f5;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 0.4s;
  
  border: 1px solid #bfbfbf;
  padding-top: 10px;
  margin-bottom: 10px;
  
`;
export const TimeListFormStyled = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding-top: 5px;
`;

const FlexSection = styled.div`
  flex: 50%;
`;


const TimeList = props => {

  const { Value, changeFun, ValidationItem, optionsWeekdays, disabledDeleteButton, DeleteTimeListItem } = props;

  return (
      <TimeListStyled>

        <DeleteItemButton
            disabled={disabledDeleteButton}
            icon={'delete'}
            OnClick={() => DeleteTimeListItem(Value.id)}
        />

        <TimeListFormStyled>

          <FlexSection>
            <BoolItem
                ItemTitle={'Включён'}
                ItemValue={Value.enabled}
                onChangeFunction={(e, v) => changeFun(Value.id, v.checked, "enabled")}
            />
          </FlexSection>

          <FlexSection>
            <SelectItem
                ItemTitle={'День недели'}
                ItemValue={Value.weekdays}

                error={ValidationItem.timeList[Value.id] ? ValidationItem.timeList[Value.id].weekdays : false}
                onChangeFunction={(e,{value}) => changeFun(Value.id, value, "weekdays")}

                Options={optionsWeekdays}
            />
          </FlexSection>

        </TimeListFormStyled>

        <TimeListFormStyled>

          <FlexSection>
            <TimeInputItem
                ItemTitle={"Время начала"}
                ItemValue={Value.startTime}

                error={ValidationItem.timeList[Value.id] ? ValidationItem.timeList[Value.id].Time : false}
                onChangeFunction={(e,{value}) => changeFun(Value.id, value, "startTime")}
            />
          </FlexSection>

          <FlexSection>
            <TimeInputItem
                ItemTitle={"Время окончание"}
                ItemValue={Value.endTime}

                error={ValidationItem.timeList[Value.id] ? ValidationItem.timeList[Value.id].Time : false}
                onChangeFunction={(e,{value}) => changeFun(Value.id, value, "endTime")}
            />
          </FlexSection>

        </TimeListFormStyled>
      </TimeListStyled>
  );
};


export default TimeList;