import React from 'react';
import styled from "styled-components";
import SortArrow from "../../Arrow/SortArrow";
import SmallTitle from "../SmallTitle";
import PropTypes from "prop-types";

export const Column1 = styled.div`
  display: flex;
  align-items: center;
  flex: 10%;
  padding: 0 10px;
  
`;

const Column = (props) => {
  const {name, text, orderingList, handleOrderingList } = props;
  return (
      <Column1>
        <SortArrow
            name={name}
            orderingList={orderingList}
        />
        <SmallTitle
            text={text}
            name={name}
            handleOrderingList={handleOrderingList}
        />
      </Column1>
  )
};

export default Column;


Column.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  orderingList: PropTypes.any,
  handleOrderingList: PropTypes.func
};

Column.defaultProps = {
  name: '',
  text: '',
  orderingList: [],
  handleOrderingList: null
};
