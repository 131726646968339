import React from 'react';
import {Button, Icon} from "semantic-ui-react";

import styled from "styled-components";

const SelectedItemsDelete = styled.div`
    height: 44px;
    margin-left: 40px;
    margin-top: 10px;
    margin-right: 40px;
    margin-bottom: 10px;
    background: #ff00002e;
    border-radius: 5px;
`;
const ContainerDiv = styled.div`
  padding-top: 4px;
  margin-left: 10px;
`;
const SelectedText = styled.div`
    float: left;
    font-size: 18px;
    padding-right: 7px;
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 8px;
    color: #777777;
`;


const SelectedItems = ( props ) => {

  const {selectedItems, onClickCancel, onClickDeleteList } = props;

  return (
      <>
        {selectedItems.length ?
            <SelectedItemsDelete>
                <ContainerDiv>
                    <SelectedText>
                        Выбрано {selectedItems.length} объектов
                    </SelectedText>

                    <Button.Group>
                        <Button onClick={onClickCancel}>
                            Отмена
                        </Button>

                        <Button.Or  text={'-'}/>

                        <Button negative onClick={onClickDeleteList}>
                            <Icon name='trash'/>
                            Удалить
                        </Button>

                    </Button.Group>
                </ContainerDiv>
            </SelectedItemsDelete>
            :
            <></>
        }
      </>
  )
};

export default SelectedItems;