import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import ServiceAPI from "../../../services";

import {AddTimeList, changeTimeList, DeleteTimeListItem} from "../../../helpersFunction/TimeListFunc";
import DimmerLoader from "../../common/Elements/Loader/DimmerLoader";

import SaveButton from "../../common/Elements/Button/SaveButton";
import {changeState} from "../../../helpersFunction/ChnageState";

import {selectSearchResult, setTvProgramSelect, validateTvProgram} from "../../../helpersFunction/TvProgram";
import TvProgramForm from "./TvProgramForm";
import {getWeekdaysOption, searchSite} from "../../../helpersFunction/TvRange";

const CreateStyled = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 10px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 15px;
  padding-bottom: 15px;
  border-style: solid;
  border-width: 1px;
      
  border-color: rgb(235, 237, 244);
  
  padding-top: 60px;
`;

const SaveContainerStyled = styled.div`
  width: 85%;
  height: 45px;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
`;

const CreatePage = () => {

  const [tvProgram, setTvProgram] = useState({
    name: '',

    sourceId: '',
    sourceName: '',

    timeList: [],
    processed: true,
  });

  const [errors, setErrors] = useState({
      validForm: false,
      name: '',
      sourceName: '',
      timeList: '',
      weekdays: ''
  });
  const [createdTvProgram, setCreatedTvProgram] = useState(false);

  const [optionsWeekdays, setOptionsWeekday] = useState([{"key": "-", "value": "-", "text": "-"}]);

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoad, setSearchLoad] = useState(false);
  const [selectSearch, setSelectSearch] = useState({});

  const service = new ServiceAPI();

  const ChangeTimeListItem = (key, value, keyField) => {
    changeTimeList(tvProgram,setTvProgram, keyField, key, value);
  };

  useEffect(() => {
    validateTvProgram(tvProgram, setErrors, errors)
  },[tvProgram]);

  const SaveTvProgram = () => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';

    setCreatedTvProgram(true);
    service.createTvProgram(tvProgram).then(res => {
      if(res.status === 201){
        window.location.href = "/tv-program"

      }else{
        document.body.style.overflow = '';
        setCreatedTvProgram(false);
      }
    })
  };

  useEffect(()=>{
    getWeekdaysOption(setOptionsWeekday)
  },[]);

  useEffect(()=>{
    searchSite(setSearchLoad, setSearchResults, searchValue);
  }, [searchValue]);

  useEffect(()=>{
    selectSearchResult(setSearchValue, setTvProgram, tvProgram, selectSearch);
  }, [selectSearch]);

  useEffect( ()=> {
    setTvProgramSelect(tvProgram, selectSearch, setTvProgram)
  }, [tvProgram.sourceName]);

  const DeleteTimeList = (id) => {
    DeleteTimeListItem(id, tvProgram, setTvProgram);
  };

  const DeleteAllTimeListItems = () => {
    setTvProgram({...tvProgram, timeList: []})
  };

  return(
    <CreateStyled>
      <DimmerLoader
          textOfLoader="Saving ..."
          active={createdTvProgram}
      />

      <TvProgramForm
          tvProgram={tvProgram}
          errorsTvProgram={errors}

          OnChangeName={e => changeState("name",e.target.value, tvProgram, setTvProgram)}
          OnChangeSourceName={e => changeState("sourceName",e.target.value, tvProgram, setTvProgram)}

          OnChangeProcessed={(e, v) => changeState("processed", v.checked, tvProgram, setTvProgram)}

          AddTimeListButton={()=>AddTimeList(tvProgram, setTvProgram)}

          ChangeTimeListItem={ChangeTimeListItem}
          optionsWeekdays={optionsWeekdays}

          searchValue={searchValue}
          onChangeSearchValue={(e, {value}) => setSearchValue(value)}
          searchResults={searchResults}
          searchLoad={searchLoad}
          onSelectSearch={(e, data)=>setSelectSearch(data.result)}

          blockDelete={false}

          disabledDeleteButton={false}
          DeleteTimeListItem={DeleteTimeList}
          DeleteTimeList={DeleteAllTimeListItems}
      />

      <SaveContainerStyled>
        <SaveButton
            disabled={!errors.validForm}
            OnClick={SaveTvProgram}
            text={'Сохранить'}
        />
      </SaveContainerStyled>

    </CreateStyled>
  )
};

export default CreatePage;