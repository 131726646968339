import React, {useEffect, useState} from 'react';

import styled from "styled-components";

import TvPlayer from "../TvPlayer/TvPlayer";
import ServiceAPI from '../../../services';

import TvArticleTextEditor from "../../common/Elements/TvArticles/TextEditor";
import {changeState} from "../../../helpersFunction/ChnageState";
import DimmerLoader from "../../common/Elements/Loader/DimmerLoader";
import SaveButton from "../../common/Elements/Button/SaveButton";
import {validateTvArticle} from "../../../helpersFunction/TvArticle";



const TvPlayerContainer = styled.div`
  display: flex;

  margin-left: 20px;
  margin-right: 20px;
`;

const TvPlayerDiv = styled.div`
  background: #ffffff;
  padding-bottom: 20px;
  flex: 30%;
  width: 49%;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 15px;

  border-color: rgb(235,237,244);
`;

const TextContainerDiv = styled.div`
  background: #ffffff;
  padding-bottom: 20px;
  // height: 800px;
  
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 15px;
  border-color: rgb(235,237,244);
  
  margin-left: 20px;
  margin-right: 20px;
  
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
`;
const SaveButtonContainer = styled.div`
   padding-top: 10px;
   padding-left: 20px;
`;

const SinglePage =  props  => {
  const { tvArticleId } = props;

  const [updateArticleFlag, setUpdateArticleFlag] = useState(false);

  const [errors, setErrors] = useState({
    validForm: false,
    title: '',
    text: '',
    //  TODO ADD PROGRAM
  });

  const [tvDate, setTvDate] = useState({
    dateStart: null,
    dateViews: null,
    dateEnd: null,

    hour: 0,
    minute: 0,
  });

  const [tvArticle, setTvArticle] = useState({
    id: 0,
    title: '',
    text: '',

    sourceId: '',
    sourceName: '',

    startTime: '',
    endTime: '',
    attendance: '',
    serverIp: '',
    folderName: '',
    program: '',
    type: '',

    prime: false,
    humanProcessed: false,
    audioProcessed: false,
  });

  const [playerState, setPlayerState] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: true,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,

    //TODO append to seek
    playedSeconds: 0,
    playbackRate: 1.0,
    loop: false,
    SinglePage: true,
  });

  const service = new ServiceAPI();

  const UpdateTvArticleState = (data) => {
    setTvArticle({
      ...tvArticle,

      id: data.id,
      title: data.title,
      text: data.text,

      sourceId: data.sourceId,
      sourceName: data.sourceName,
      attendance: data.attendance,

      startTime: data.startTime,
      endTime: data.endTime,

      serverIp: data.serverIp,
      folderName: data.folderName,
      type: data.type,

      program: data.program,
      prime: data.prime,
      humanProcessed: data.humanProcessed,
      audioProcessed: data.audioProcessed,

    });
  };

  useEffect(() => {
    service.getTvPlot(tvArticleId).then(resp => {
      if (resp.status !== 200){
        return;
      }

      let responseData = resp.data;

      let dateStart = new Date(responseData.startTime);
      dateStart.setSeconds(dateStart.getSeconds()-1);
      let dateEnd = new Date(responseData.endTime);

      dateEnd.setSeconds(dateEnd.getSeconds()+1);
      let copiedDate = new Date(dateStart);

      UpdateTvArticleState(responseData);

      setTvDate({...tvDate ,
        dateStart: copiedDate,
        dateEnd: dateEnd,
        dateViews:dateStart,

        minute: dateStart.getMinutes(),
        hour: dateStart.getHours()
      });
      setPlayerState({...playerState, url: responseData.url});
    })
  },[]);

  const updateArticle = () => {
    setUpdateArticleFlag(true);
    service.updateTvArticle(tvArticle, tvArticleId).then(resp => {
      let responseData = resp.data;

      UpdateTvArticleState(responseData);

      setUpdateArticleFlag(false);
    })
  };


  useEffect(() => {
    validateTvArticle(tvArticle, setErrors, errors)
  },[tvArticle]);

  return (
      <>
        <DimmerLoader
            textOfLoader="Сохранение ..."
            active={updateArticleFlag}
        />

        {/* TV PLAYER */}
        <TvPlayerContainer >
          <TvPlayerDiv>
            <TvPlayer
                playerState={playerState}
                setPlayerState={setPlayerState}
                tvDate={tvDate}
                setTvDate={setTvDate}
            />
          </TvPlayerDiv>
        </TvPlayerContainer>

        <TextContainerDiv>
          <TvArticleTextEditor
              textValue={tvArticle.text}
              onChangeText={(e) => changeState("text", e.target.value, tvArticle, setTvArticle)}

              titleValue={tvArticle.title}
              onChangeTitle={(e) => changeState("title", e.target.value, tvArticle, setTvArticle)}
              articleError={errors}

              sourceName={tvArticle.sourceName}
              sourceProgram={tvArticle.program}
          />

          <SaveButtonContainer>
            <SaveButton
                disabled={!errors.validForm || !tvArticle.audioProcessed}
                OnClick={updateArticle}
                text={'Сохранить'}
            />
          </SaveButtonContainer>
        </TextContainerDiv>

      </>
  )
};
export default SinglePage;
