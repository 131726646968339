import React from 'react';


const NotFound = () => {
  window.location.href = '/tv-articles';

  return (
    <>
      <div>
        Not Found
      </div>
    </>
  )
};

export default NotFound;