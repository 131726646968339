
const BeautifulNumeric = (value) => {
  if (value.toString().length === 1){
    value = "0" + value
  }
  return value;
};

export const GetUrlFromDate = (serverIp, folderName, date, type) =>{
  let url = serverIp+`/${folderName}/`;

  let year = date.getFullYear();

  let month = date.getMonth()+1;
  month = BeautifulNumeric(month);

  let day = date.getDate();
  day = BeautifulNumeric(day);

  let hour = date.getHours();
  hour = BeautifulNumeric(hour);

  let min = date.getMinutes();
  min = BeautifulNumeric(min);
  let file_format = '.mp3'
  if (type == 'tv'){
    file_format = '.mp4'
  } else if (type == 'radio') {
    file_format = '.mp3'
  }
  url += `${year}/${month}/${day}/${hour}/${min}${file_format}`;

  return url;
};