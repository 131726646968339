import React from 'react';

import { Map } from 'react-lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import TableRowTvProgram from "../../common/Elements/TvProgram/TableTvProgram";
import CheckBoxSelected from "../../common/Elements/Button/CheckBoxSelected";
import PageLoader from "../../common/Elements/Loader/PageLoader";

const TvProgramListContainerStyled = styled.div`
  overflow: auto;
`;

const ContentWrapperStyled = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const ItemContainer = styled.div`
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

const TvProgramList = ( props ) => {

  const { tvProgramLoad, tvPrograms, selectedItems, onClickCheckBox } = props;

  return (
      <ContentWrapperStyled>
        <PageLoader
            Load={tvProgramLoad}
        />
        <TvProgramListContainerStyled id="TvProgramListContainer">
          <Map
              collection={tvPrograms}
              iteratee={value => {
                return (
                  <div key={value.id} >
                    <CheckBoxSelected
                        selectedItems={selectedItems.indexOf(value.id) !== -1}
                        onClickCheckBox={()=>onClickCheckBox(value.id)}
                    />

                    <ItemContainer>
                      <Link
                          key={value.id}
                          to={`/tv-program/${value.id}`}
                          exact={"true"}
                      >
                        <TableRowTvProgram
                            tvProgramId={value.id}
                            tvProgramName={value.name}
                            tvProgramSourceName={value.sourceName}
                            tvProgramDate={value.createdDate}
                            tvProgramProcessed={value.processed}
                        />
                      </Link>
                    </ItemContainer>
                  </div>
                );
              }}
          />
         </TvProgramListContainerStyled>
       </ContentWrapperStyled>
  )
};

export default TvProgramList;