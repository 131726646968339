import React from 'react';

import Header from "../containers/Header";
import TabCreate from "../containers/TabPage";
import SinglePage from "../containers/TvArticles/CreatePage";


const CreateTvArticle = () => {
    return (
        <>
            <Header />

            <TabCreate
                link={'/tv-range'}
                showButtonPage={false}
                showButton={false}
                showCreateButton={true}
                showListButton={true}
            />

            <SinglePage />
        </>
    )
};
export default CreateTvArticle;