import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';


export const TextStyled = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  
`;
export const TextContainer = styled.div`
  color: rgb(102, 101, 105);;
  ${props =>
    props.name &&
    css`
      :hover {
        color: rgb(79, 77, 89);
        cursor: pointer;
        font-weight: 600;
      }
    `}
`;

const SmallTitle = props => {
  const { text, name, handleOrderingList } = props;

  return (
      <TextStyled>
          <TextContainer
              name={name}
              onClick={() => handleOrderingList && handleOrderingList(name)}
            >
            {text}
          </TextContainer>
      </TextStyled>
  );
};

export default SmallTitle;

SmallTitle.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  handleOrderingList: PropTypes.func
};

SmallTitle.defaultProps = {
  text: '',
  name: '',
  handleOrderingList: null
};
