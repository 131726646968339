import React from 'react';

import {Checkbox} from 'semantic-ui-react'
import styled from "styled-components";

const CheckBoxContainer = styled.div`
    float: left;
    padding-top: 11px;
    padding-left: 18px;
        
`;

const CheckBoxSelected = (props) => {
  const {selectedItems, onClickCheckBox} = props;
  return(
      <CheckBoxContainer>
        <Checkbox
            checked={selectedItems}
            onChange={onClickCheckBox}
        />
      </CheckBoxContainer>
  )
};


export default CheckBoxSelected;
