import {parseQuery} from "./parseQuery";


export function setPageList(resp, pages, setPages) {
  let nextPage = '';
  let prevPage = '';

  if(resp.data.next){
    nextPage = parseQuery(resp.data.next);
  }

  if(resp.data.previous){
    prevPage = parseQuery(resp.data.previous);
  }

  setPages({...pages,
    prev: prevPage,
    next: nextPage
  })
}