import styled from "styled-components";

export const SettingItemStyled = styled.div`
  display: flex;
  align-items: center;
  width: 85%;
  padding: 20px 20px 20px 30px;
  box-sizing: border-box;
  border-top: 1px solid #dddddd;
  flex-direction: row;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
`;
