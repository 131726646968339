import React from 'react';
import Header from '../containers/Header';
import TvRangeListPage from "../containers/TvRange/LIstPage";

const TvRange = () => {
  return (
      <>
        <Header />

        <TvRangeListPage />
      </>
  )
};
export default TvRange;