import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableItem from "../Table/TableItem";
import TableIconItem from "../Table/ImgItem";

export const TableRowStyled = styled.div`
  display: flex;
  padding: 8px 0;
  margin: 20px 0;
  font-size: 14px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border-radius: 8px;
    background: #b1b1b1;
    opacity: 0.7;
  }
`;

const TableRowTvArticle = props => {
  const {
    tvArticleId,
    tvArticleTitle,
    tvArticleSourceName,
    tvArticleDateStart,
    tvArticleDateEnd,
    tvArticleAudioProcessed,
    tvArticleHumanProcessed,
    tvArticlePrime
  } = props;

  //TODO SET BEAUTIFUL
  let color = '#4183c4;';
  if (tvArticleAudioProcessed){
    color = '#8c9e00;'
  }
  return (
      <TableRowStyled>
        <TableItem
            name={tvArticleTitle}
            color={color}
        />
        <TableItem
            name={tvArticleSourceName}
            color={color}
        />
        <TableItem
            name={tvArticleDateStart}
            color={color}
        />
        <TableItem
            name={tvArticleDateEnd}
            color={color}
        />
        <TableIconItem
            value={tvArticleAudioProcessed}
        />
        <TableIconItem
            value={tvArticleHumanProcessed}
        />
        <TableIconItem
            value={tvArticlePrime}
        />
      </TableRowStyled>
  );
};

export default TableRowTvArticle;

TableRowTvArticle.propTypes = {
  reportUrl: PropTypes.string,
  tvProgramUrll: PropTypes.string,
  tvProgramNamel: PropTypes.string,
  tvProgramSourceNamel: PropTypes.string,
  tvProgramDatel: PropTypes.string,
  tvProgramProcessedl: PropTypes.string,
};

TableRowTvArticle.defaultProps = {
  reportUrl: '',
  tvProgramUrll: '',
  tvProgramNamel: '',
  tvProgramSourceNamel: '',
  tvProgramDatel: '',
  tvProgramProcessedl: '',
};
