import React from 'react';
import {Dimmer, Loader} from 'semantic-ui-react'
import PropTypes from "prop-types";


const DimmerLoader = (props) => {
  const {textOfLoader, active} = props;
  return(
      <Dimmer
          active={active}
          style={{position: 'fixed'}}
      >
        <Loader indeterminate>
          {textOfLoader}
        </Loader>
      </Dimmer>
  )
};

export default DimmerLoader;


DimmerLoader.propTypes = {
  textOfLoader: PropTypes.string,
  active: PropTypes.bool,
};

DimmerLoader.defaultProps = {
  textOfLoader: '',
  active: false,
};