import React from "react";

import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import styled from "styled-components";
import {Input} from "semantic-ui-react";


const LabelContainer = styled.div`
    padding-top: 10px;
    width: 12%;
    text-align: center;
    box-sizing: inherit;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
`;

const LabelText = styled.label`
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 22px;
    line-height: 1.4285em;
    color: rgba(0,0,0,.87);
`;

const TextAreaContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 1px solid #cba3a8;
  padding-bottom: 20px;
`;

const InputContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 1px solid #cba3a8;
  padding-bottom: 20px;
`;


// TODO change form program and source
const TvArticleTextEditor = (props) => {
  const {
    textValue,
    onChangeText,

    titleValue,
    onChangeTitle,
    articleError,

    sourceName,
    sourceProgram
  } = props;

  return (
      <>
        <LabelContainer>
          <LabelText>
            Заголовок
          </LabelText>
        </LabelContainer>

        <InputContainer>
          <Input
              error={articleError.title}
              style={{ minWidth: '100%' }}

              placeholder={"Заголовок"}
              onChange={onChangeTitle}
              value={titleValue}
          />
        </InputContainer>

        <LabelContainer>
          <LabelText>
            Распознанный текст
          </LabelText>
        </LabelContainer>

        <TextAreaContainer>
          <TextArea
              placeholder='Распознанный текст'
              style={{ minHeight: 100, maxWidth:'100%', minWidth:'100%', maxHeight: '350px' }}
              value={textValue}
              onChange={onChangeText}
          />
        </TextAreaContainer>

        <LabelContainer>
          <LabelText>
            Программа
          </LabelText>
        </LabelContainer>

        <InputContainer>
          <Input
              disabled={true}
              style={{ minWidth: '100%' }}
              placeholder={"Программа"}
              value={sourceProgram}
          />
        </InputContainer>

        <LabelContainer>
          <LabelText>
            Название источника
          </LabelText>
        </LabelContainer>

        <InputContainer>
          <Input
              disabled={true}
              style={{ minWidth: '100%' }}
              placeholder={"Название источника"}
              value={sourceName}
          />
        </InputContainer>

      </>
  );
};

export default TvArticleTextEditor;