import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Column from "../Table/ColumnItem";


export const TableRowStyled = styled.div`
  display: flex;
  border-bottom: 1px solid;
  padding: 8px 0;

  font-size: 15px;
  
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    background-color: inherit;
  }
`;


const TableHeaderTvArticles = ( props ) => {
  const {handleOrderingList, orderingList} = props;

  return (
      <TableRowStyled>
        <Column
            name={"title"}
            text={"Заголовок"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
        <Column
            name={"source_name"}
            text={"Источник"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
        <Column
            name={"start_time"}
            text={"Время начала"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
        <Column
            name={"end_time"}
            text={"Время окончание"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
        <Column
            name={"audio_processed"}
            text={"Видео нарезано"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
        <Column
            name={"human_processed"}
            text={"Расшифровано человеком"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
        <Column
            name={"prime"}
            text={"Прайм"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
      </TableRowStyled>
  );
};

export default TableHeaderTvArticles;

TableHeaderTvArticles.propTypes = {
  handleOrderingList: PropTypes.func,
  orderingList: PropTypes.array
};

TableHeaderTvArticles.defaultProps = {
  handleOrderingList: null,
  orderingList: []
};
