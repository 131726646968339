import * as types from './actionsTypes';

export const initialState = {
  isAuthenticated: false,
  user: JSON.parse(localStorage.getItem('user')) || {},
  token: JSON.parse(localStorage.getItem('token')) || null,
  isLoading: false,
  errorMessage: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.LOGIN:
      localStorage.setItem('token', JSON.stringify(action.payload.access));
      localStorage.setItem(
        'tokenRefresh',
        JSON.stringify(action.payload.refresh)
      );
      localStorage.setItem('user', JSON.stringify(action.payload.user));

      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        user: action.payload.user
      };

    case types.LOGOUT:
      localStorage.clear();
      window.location.href = '/';
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };

    default:
      return state;
  }
};

export default reducer;
