import React from 'react';
import styled from 'styled-components';
import {Button, Icon} from 'semantic-ui-react'
import PropTypes from "prop-types";


//TODO create one elements of buttons with settings
const StyledPadding = styled.div`
  padding-left: 10px;
`
const DeleteItemButton = (props) => {
  const {OnClick, text, icon} = props;
  return(
      <StyledPadding>
        <Button
            onClick={OnClick}
        >
          <Icon name={icon} />
          {text}
        </Button>
      </StyledPadding>
  )
};


export default DeleteItemButton;


DeleteItemButton.propTypes = {
  OnClick: PropTypes.any,
  text: PropTypes.string,
  icon: PropTypes.string
};

DeleteItemButton.default = {
  OnClick: () => {},
  text: '',
  icon: ''
};