import React from 'react';
import styled from 'styled-components';
import Column from "../Table/ColumnItem";
import PropTypes from "prop-types";


export const TableRowStyled = styled.div`
  display: flex;
  border-bottom: 1px solid;
  padding: 8px 0;

  font-size: 15px;
  
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    background-color: inherit;
  }
`;


const TableHeaderTvRangeCut = (props) => {
  const { handleOrderingList, orderingList } = props;

  return (
      <TableRowStyled>
        <Column
            name={"title"}
            text={"Заголовок"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
        <Column
            name={"start_time"}
            text={"Время начала"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
        <Column
            name={"end_time"}
            text={"Время окончание"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
        <Column
            name={"date"}
            text={"Дата"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
        <Column
            name={"weekdays"}
            text={"День недели"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
        <Column
            name={"processed"}
            text={"Обработан"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
          <Column
            name={"prime"}
            text={"Прайм"}
            orderingList={orderingList}
            handleOrderingList={handleOrderingList}
        />
      </TableRowStyled>
  );
};

export default TableHeaderTvRangeCut;


TableHeaderTvRangeCut.propTypes = {
  handleOrderingList: PropTypes.func,
  orderingList: PropTypes.array
};

TableHeaderTvRangeCut.defaultProps = {
  handleOrderingList: PropTypes.func,
  orderingList: PropTypes.array
};