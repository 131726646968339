


export const SelectAll = (Array, state, setState, keyItem, checked) =>{
  if (checked){
    let idPage = [];
    Array.forEach(item => idPage.push(item.id));
    setState({...state, [keyItem]: idPage})
  }else{
    setState({...state, [keyItem]: []})
  }
};