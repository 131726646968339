import React, { useReducer } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import GlobalContext from './context/global';
import reducer, { initialState } from './context/reducers/global';
import { Provider } from 'react-redux';

import store from './context/redux/store';

import 'semantic-ui-css/semantic.min.css'
import './App.css';

//TV program
import TvProgram from "./components/layouts/TvProgram";
import SingleTvProgram from "./components/layouts/SingleTvProgram";
import CreateTvProgramPage from "./components/layouts/CreateTvProgramPage";

//TV range
import TvRange from "./components/layouts/TvRange";
import CreateTvRangePage from "./components/layouts/CreateTvRangePage";
import SingleTvRange from "./components/layouts/SingleTvRange";
import TvArticles from "./components/layouts/TvArticles";
import SingleTvArticle from "./components/layouts/SingleTvArticle";
import LoginPage from "./components/layouts/LoginPage";
import { Redirect } from "react-router-dom";
import NotFound from "./components/layouts/NotFound";
import SingleTvRangeCutPage from "./components/layouts/SingleTvRangeCut";
import TvRangeCut from "./components/layouts/TvRangeToCut";
import YoutubeArticle from "./components/layouts/YouTubeArticle";
import YouTubeArticleSinglePage from "./components/layouts/YouTubeAticlePage";
import CreateTvArticle from "./components/layouts/CreateTvArticle";

//Biling
import BillingTable from "./components/containers/BillingTable";

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
      <GlobalContext.Provider
          value={{ dispatchGlobal: dispatch, stateGlobal: state }}
      >
        <Provider store={store}>
          {localStorage.getItem('token') ? (
            <Router>
              <Switch>

                /*TV _ PROGRAM */
                <Route path="/tv-program" exact>
                  <TvProgram />
                </Route>

                <Route path="/billing/" exact >
                  <BillingTable />
                </Route>

                <Route path="/tv-program/create" exact>
                  <CreateTvProgramPage />
                </Route>

                <Route
                  path="/tv-program/:tvProgramId"
                  render={props => (
                      <SingleTvProgram
                          key={props.match.params.tvProgramId}
                      />
                  )}
                  exact
                />

                /*TV _ Range */
                <Route path="/tv-range" exact>
                  <TvRange />
                </Route>

                <Route path="/tv-range/create" exact>
                  <CreateTvRangePage />
                </Route>

                <Route
                  path="/tv-range/:tvRangeId"
                  render={props => (
                      <SingleTvRange
                          key={props.match.params.tvRangeId}
                      />
                  )}
                  exact
                />
                {/*Video to Cut */}
                <Route path="/tv-range-cut" exact>
                  <TvRangeCut />
                </Route>

                <Route
                    path="/tv-range-cut/:tvRangeId"
                    render={props => (
                        <SingleTvRangeCutPage
                            key={props.match.params.tvRangeId}
                        />
                    )}
                    exact
                />
                /* TV _ Yt Articles */
                <Route path="/youtube-article"
                         exact>
                  <YoutubeArticle/>
                </Route>

                <Route
                    path="/youtube-article/:YtArticle"
                    exact
                    render={props => (
                        <YouTubeArticleSinglePage
                            key={props.match.params.YtArticle}
                        />
                    )}
                >
                </Route>

                /* TV _ Plot */
                <Route
                    path="/tv-articles/"
                    exact
                >
                  <TvArticles />
                </Route>

                  <Route path="/tv-articles/create" exact>
                      <CreateTvArticle />
                  </Route>

                  <Route
                  path="/tv-articles/:tvArticleId"
                  render={props => (
                      <SingleTvArticle
                          key={props.match.params.tvArticleId}
                      />
                  )}
                  exact
                />

                <Route component={NotFound} />
              </Switch>
            </Router>
          ) : (
            <Router>
              <Switch>
                <Route
                  path="/"
                  exact
                >
                  <LoginPage/>
                </Route>
                <Redirect from="*" to="/" />
              </Switch>
            </Router>
          )}
        </Provider>
      </GlobalContext.Provider>
  );
}

export default App;
