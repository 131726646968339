import React from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import {Button, Checkbox, Icon} from 'semantic-ui-react'
import PropTypes from "prop-types";
import PagesButtons from "../common/Elements/Button/PagesButtons";
import SearchButton from "../common/Elements/Button/SearchButtons";


export const TabStyled = styled.div`
    // background: black;
    display: flex;
    height: 45px;
    margin-left: 10px;
    margin-right: 40px;
    
`;
export const PagesButtonStyled = styled.div`
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
`;

export const SearchStyled = styled.div`
    padding-right: 15px;
    padding-left: 15px;
`;
const CheckBoxContainer = styled.div`
    text-align: center;
    width: 30px;
    padding-right: 10px;
`;
const  CheckBoxInputDiv = styled.div`
    margin-top: 50%;
`
const PassDiv = styled.div`
  margin-right: 30px;
`;

const MenuButtonDiv = styled.div`
  padding-right: 10px;
`;


const TabCreate = (props) => {

  const {
    showButton,
    pages,
    onClickPage,
    dataLoaded,
    link,
    OnChange,
    SearchFieldChange,
    optionsSearch,
    showCreateButton,
    onClickAllCheckBox,
    showListButton,
    searchValue,
    showButtonPage,
  } = props;

  return (
        <TabStyled>
          {showButton ?
              <CheckBoxContainer>
                <CheckBoxInputDiv>
                  <Checkbox
                      onChange={onClickAllCheckBox}
                  />
                </CheckBoxInputDiv>
              </CheckBoxContainer>
              :
              <PassDiv> </PassDiv>
          }
          { showListButton ?
              <MenuButtonDiv>
                <Link
                    to={link}
                    exact="true">

                  <Button primary>
                    <Icon name='list' />
                    Список
                  </Button>

                </Link>
              </MenuButtonDiv>
                :
                <>
                </>
          }

          {showCreateButton &&
              <MenuButtonDiv>
                <Link
                    to={link+"/create"}
                    exact="true">

                  <Button primary>
                    <Icon name='add' />
                    Создать
                  </Button>

                </Link>
              </MenuButtonDiv>
          }

          {showButtonPage &&
            <SearchStyled>
              <SearchButton
                  dataLoaded={dataLoaded}
                  OnChange={OnChange}
                  SearchFieldChange={SearchFieldChange}
                  optionsSearch={optionsSearch}
                  searchValue={searchValue}
              />
              </SearchStyled>
          }

          { showButtonPage &&
            <PagesButtonStyled>
              <PagesButtons
                  pages={pages}
                  onClickPage={onClickPage}
                  dataLoaded={dataLoaded}
              />

            </PagesButtonStyled>
          }
        </TabStyled>
  )
};

export default TabCreate;

TabCreate.defaultProps = {
  link: '',
  showButton: false,
  showButtonPage: false,
  pages: {
    prev: '',
    next: ''
  },
  onClickPage: ()=>null,
  tvProgramLoad: false,

  OnChange: null,
  SearchFieldChange: null,
  optionsSearch: [{'key': '-', 'text': '-', 'title':'-'}],
  showCreateButton: false,
  onClickAllCheckBox: null,
  searchValue: '',
  showListButton: false,
  dataLoaded: true,
};

TabCreate.propTypes = {
  link: PropTypes.string,
  showButton: PropTypes.bool,
  showButtonPage: PropTypes.bool,
  pages: PropTypes.any,
  onClickPage: PropTypes.func ,
  tvProgramLoad: PropTypes.bool,

  OnChange: PropTypes.func,
  SearchFieldChange: PropTypes.func,
  optionsSearch: PropTypes.array,
  showCreateButton: PropTypes.bool,
  onClickAllCheckBox: PropTypes.func,
  searchValue: PropTypes.string,
  showListButton: PropTypes.bool,
  dataLoaded: PropTypes.bool,
};
