import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableItem from "../Table/TableItem";
import TableIconItem from "../Table/ImgItem";

export const TableRowStyled = styled.div`
  display: flex;
  padding: 8px 0;
  margin: 20px 0;
  font-size: 14px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border-radius: 8px;
    background: #b1b1b1;
    opacity: 0.4;
  }
`;

const TableRowTvProgram = props => {
  const {
    tvProgramId,
    tvProgramName,
    tvProgramSourceName,
    tvProgramDate,
    tvProgramProcessed,
  } = props;

  return (
      <TableRowStyled>
        <TableItem
          name={tvProgramName}
        />
        <TableItem
            name={tvProgramSourceName}
        />
        <TableItem
            name={tvProgramDate}
        />
        <TableIconItem
            value={tvProgramProcessed}
        />
      </TableRowStyled>
  );
};

export default TableRowTvProgram;

TableRowTvProgram.propTypes = {
  reportUrl: PropTypes.string,
  tvProgramUrll: PropTypes.string,
  tvProgramNamel: PropTypes.string,
  tvProgramSourceNamel: PropTypes.string,
  tvProgramDatel: PropTypes.string,
  tvProgramProcessedl: PropTypes.string,
};

TableRowTvProgram.defaultProps = {
  reportUrl: '',
  tvProgramUrll: '',
  tvProgramNamel: '',
  tvProgramSourceNamel: '',
  tvProgramDatel: '',
  tvProgramProcessedl: '',
};
