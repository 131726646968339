import React, { Fragment, useState, useEffect } from 'react'
import { Table, Input } from 'semantic-ui-react'
import ServiceAPI from '../../../services';

import Header from '../Header';
const headers = [
    "Оператор",
    "Кол-во сюжетов",
    "Общее время расшифровки",
]
const BillingTableHeader = () => {
    return (
        <Table.Header>
            <Table.Row>
                {headers.map(el => <Table.HeaderCell>{el}</Table.HeaderCell>)}
            </Table.Row>
        </Table.Header>
    )
}

const BillingRow = ({data}) => (
    <Table.Row>
        <Table.Cell>{data.username}</Table.Cell>
        <Table.Cell>{data.totalAmount}</Table.Cell>
        <Table.Cell>{data.totalDuration}</Table.Cell>
    </Table.Row>
)

const BillingTableBody = ({dateRange}) => {
    const [billingData, setBillingData] = useState([]);
    const service = new ServiceAPI();

    useEffect(() => {
        if (dateRange)
            service.getBillingData(dateRange).then(({data}) => {
                if (data) {
                    setBillingData(data.results);
                }
            })
    }, [dateRange])
    console.log(billingData)
    const rows = billingData.map(row => <BillingRow data={row}/>)
    return (
        <Table.Body>
            {rows || null}
        </Table.Body>
    )
}


const BillingTable = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateError, setDateError] = useState(false);
    const [dateRange, setDateRange] = useState(null);
    useEffect(() => {
        if (!(startDate || endDate))  {
            setDateError(false);
            setDateRange({});
        } else if (!(startDate && endDate))  {
            setDateError(false);
        } else if (startDate <= endDate) {
            setDateError(false);
            setDateRange({startDate, endDate});
        } else {
            setDateError(true);
            setDateRange(null);
        }
    }, [startDate, endDate])
    return (
        <Fragment>
            <Header/>
            <Input
                error={dateError}
                type="date"
                size="small"
                value={startDate}
                step="1"
                onChange={e => setStartDate(e.target.value)}
            />
            <Input
                error={dateError}
                type="date"
                size="small"
                value={endDate}
                step="1"
                onChange={e => setEndDate(e.target.value)}
            />
            <Table celled>
                <BillingTableHeader />
                <BillingTableBody dateRange={dateRange}/>
            </Table>
        </Fragment>
    )
}

export default BillingTable;
