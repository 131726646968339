import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableItem from "../Table/TableItem";
import TableIconItem from "../Table/ImgItem";

const TableRowStyled = styled.div`
  display: flex;
  padding: 8px 0;
  margin: 20px 0;
  font-size: 14px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border-radius: 8px;
    background: #b1b1b1;
    opacity: 0.7;
  }
`;

const TableRowTvRange = props => {
  const {
    tvProgramId,
    tvRangeName,
    tvRangeSourceName,
    tvRangeDate,
    tvRangeProcessed,
    tvRangePrime,
    tvRangeEnabled,
  } = props;

  return (
      <TableRowStyled>
        <TableItem
            name={tvRangeName}
        />
        <TableItem
            name={tvRangeSourceName}
        />
        <TableItem
            name={tvRangeDate}
        />

        <TableIconItem
            value={tvRangeProcessed}
        />
        <TableIconItem
            value={tvRangePrime}
        />
        <TableIconItem
            value={tvRangeEnabled}
        />
      </TableRowStyled>
  );
};

export default TableRowTvRange;


TableRowTvRange.propTypes = {
  reportUrl: PropTypes.string,
  tvRangeName: PropTypes.string,
  tvRangeSourceName: PropTypes.string,
  tvRangeDate: PropTypes.string,
  tvRangeProcessed: PropTypes.bool,
  tvRangePrime: PropTypes.bool,
  tvRangeEnabled: PropTypes.bool,
};

TableRowTvRange.defaultProps = {
  reportUrl: '',
  tvRangeName: '',
  tvRangeSourceName: '',
  tvRangeDate: '',
  tvRangeProcessed: false,
  tvRangePrime: false,
  tvRangeEnabled: false,
};
