import {Form} from "semantic-ui-react";
import InputItem from "../../common/Elements/Form/Items/InputItem";

import BoolItem from "../../common/Elements/Form/Items/BoolItem";
import TimeListMap from "../TimeList/TimeListMap";
import AddButton from "../../common/Elements/Button/AddButton";

import React from "react";
import DeleteButton from "../../common/Elements/Button/DeleteButton";
import styled from "styled-components";
import TvSourceNameForm from "../FormSourceName";


const TimeListWrapped = styled.div`
  background: #f7f7f757;
  border-radius: 10px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 15px;

  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;

  border-color: rgb(169, 169, 169);
`;

const TvRangeForm = (props) => {
    const {
        tvRange,
        errorsTvRange,

        OnChangeType,
        OnChangeName,
        OnChangeSourceName,
        OnChangeFolderName,
        OnChangeServerIp,
        OnChangeProcessed,
        OnChangePrime,
        OnChangeEnabled,

        AddTimeListButton,

        ChangeTimeListItem,
        optionsWeekdays,

        searchValue,
        onChangeSearchValue,
        searchResults,
        searchLoad,
        onSelectSearch,

        blockDelete,

        DeleteTimeList,
        disabledDeleteButton,
        DeleteTimeListItem
    } = props;
    return (
        <>
            <Form>
                <Form.Field>
                    <InputItem
                        ItemTitle="Название"
                        ItemId="name"
                        ItemValue={tvRange.name}
                        ValidationItem={errorsTvRange.name}
                        onChangeFunction={OnChangeName}
                    />
                </Form.Field>

                <TvSourceNameForm
                    sourceName={tvRange.sourceName}
                    errorSourceName={errorsTvRange.sourceName}
                    OnChangeSourceName={OnChangeSourceName}

                    searchValue={searchValue}
                    onChangeSearchValue={onChangeSearchValue}

                    searchResults={searchResults}
                    searchLoad={searchLoad}
                    onSelectSearch={onSelectSearch}

                    folderName={tvRange.folderName}
                    errorFolderName={errorsTvRange.folderName}
                    OnChangeFolderName={OnChangeFolderName}

                    serverIp={tvRange.serverIp}
                    errorServerIp={errorsTvRange.serverIp}
                    OnChangeServerIp={OnChangeServerIp}

                    type={tvRange.type}
                    OnChangeType={OnChangeType}
                />

                <Form.Field>
                    <BoolItem
                        ItemTitle="Обработка"
                        ItemId="processed"
                        ItemValue={tvRange.processed}
                        onChangeFunction={OnChangeProcessed}
                    />
                </Form.Field>

                <Form.Field>
                    <BoolItem
                        ItemTitle="Прайм"
                        ItemId="prime"
                        ItemValue={tvRange.prime}
                        onChangeFunction={OnChangePrime}
                    />
                </Form.Field>

                <Form.Field>
                    <BoolItem
                        ItemTitle="Включён"
                        ItemId="enabled"
                        ItemValue={tvRange.enabled}
                        onChangeFunction={OnChangeEnabled}
                    />
                </Form.Field>

                <TimeListWrapped>
                    <TimeListMap
                        collection={tvRange.timeList}
                        errors={errorsTvRange}
                        ChangeTimeListItem={ChangeTimeListItem}
                        optionsWeekdays={optionsWeekdays}

                        disabledDeleteButton={disabledDeleteButton}
                        DeleteTimeListItem={DeleteTimeListItem}
                    />

                    <AddButton
                        OnClick={AddTimeListButton}
                        text={"Добавить"}
                    />

                    {!blockDelete && tvRange.timeList.length ? (
                            <DeleteButton
                                OnClick={DeleteTimeList}
                                text={'Убрать все объекты'}
                                icon={'trash'}
                            />
                        )
                        :
                        (<></>)
                    }

                </TimeListWrapped>
            </Form>
        </>
    )
};

export default TvRangeForm;