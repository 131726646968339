import React, {useEffect, useState} from 'react';
import InputCreateVideo from "../../common/Elements/YouTube/createInputElement";
import TableHeaderYoutubeArticles from "../../common/Elements/YouTube/TableheaderYoutubeArticles";
import {changeState} from "../../../helpersFunction/ChnageState";
import TabCreate from "../TabPage";
import YouTubeArticlesList from "./TableList";
import {selectCheckBox} from "../../../helpersFunction/CheckBoxDelete";
import {setPageList} from "../../../helpersFunction/SetPage";
import ServiceAPI from "../../../services";
import SelectedItems from "../../common/Elements/SelectedItems";
import {SelectAll} from "../../../helpersFunction/SelectAllId";
import {OrderFieldsList} from "../../../helpersFunction/OrderFields";




const YoutubeListPage = (props) => {
    const optionsSearch = [
        { key: 'title', text: 'Заголовок', value: 'title' },
        { key: 'url', text: 'Url', value: 'url' },
    ];
    const service = new ServiceAPI();
    const [selectedItems, setSelectedItems] = useState({selectedItems:[]});
    const [YTArticlesRanges, setYTArticlesRanges] = useState([]);
    const [YTLoad, setYTLoad] = useState(false);
    const [linkToSave, setLinkToSave] = useState({
        linkToSave: '', validation: true
    });

    const [search, setSearch] = useState({
        searchField: 'title',
        searchValue: ''
    });

    const [pages, setPages] = useState({
        prev: '',
        next: ''
    });
    const [orderingList, setOrderingList] = useState([]);

    const createVideo = () => {
        service.createYTArticles({url: linkToSave.linkToSave}).then(resp => {
            if (resp.data){
                getListYTArticles(null)
                setLinkToSave({validation: true, linkToSave: ''})
            }
        })
    }
    const getListYTArticles = ( params ) => {
        setYTLoad(false);
        setYTArticlesRanges([]);

        service.getYTArticles(params ? params : null).then(resp => {
            if (resp.data) {
                setYTArticlesRanges(resp.data.results);
                setPageList(resp, pages, setPages);
                setYTLoad(true);
            }
        });
    };

    const changeLink = (e) =>{
        let value = e.target.value
        let validation = true
        //TODO
        if (value.includes("https://www.youtube.com/watch?v=")){
            validation = false
        }
        setLinkToSave({...linkToSave, linkToSave: value, validation: validation})
    }
    const onClickDeleteList = () => {
        service.deleteYTArticles(selectedItems).then(resp => {
            setSelectedItems({...selectedItems, selectedItems: []});
            getListYTArticles(null);
            setSearch({...search, searchValue:''})
        })
    };
    const handleOrderingList = (name) => {
        OrderFieldsList(orderingList, setOrderingList, name)
    };
    const ChangePage = (params) => {
        getListYTArticles(params)
    };
    const onClickCheckBox = (event) => {
        selectCheckBox(event, selectedItems, setSelectedItems)
    };

    useEffect(() => {
        if (search.searchValue || orderingList) {
            let filter = {};

            if (orderingList && orderingList.length > 0) {
                filter.ordering = orderingList
            }

            if (search.searchValue) {
                filter[search.searchField] = search.searchValue
            }

            getListYTArticles(filter ? filter : null);
        }
    }, [orderingList, search]);

    return (
        <>
            <InputCreateVideo
                OnClick={createVideo}
                OnChange={changeLink}
                value={linkToSave.linkToSave}
                validationError={linkToSave.validation}
            />
            <TabCreate
                link={'/tv-range'}
                showButtonPage={true}
                showButton={true}
                showCreateButton={false}
                showListButton={false}
                pages={pages}
                onClickPage={ChangePage}
                optionsSearch={optionsSearch}
                OnChange={(e, {v})=> changeState("searchValue", e.target.value, search, setSearch)}
                SearchFieldChange={(e, v)=> changeState("searchField", v.value, search, setSearch)}
                onClickAllCheckBox={(e, v)=>SelectAll(YTArticlesRanges, selectedItems, setSelectedItems, "selectedItems", v.checked)}
                dataLoaded={YTLoad}
                searchValue={search.searchValue}
            />

            <SelectedItems
                selectedItems={selectedItems.selectedItems}
                onClickCancel={()=> changeState("selectedItems", [], selectedItems, setSelectedItems)}
                onClickDeleteList={onClickDeleteList}
            />

            <TableHeaderYoutubeArticles
                handleOrderingList={handleOrderingList}
                orderingList={orderingList}
            />

            <YouTubeArticlesList
                YTLoad={YTLoad}
                YTArticlesRanges={YTArticlesRanges}
                onClickCheckBox={onClickCheckBox}
                selectedItems={selectedItems.selectedItems}
            />
        </>
    )
}

export default YoutubeListPage;