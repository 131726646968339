import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as SortTwoArrowsSvg } from '../../../images/icons/sort.svg';
import { ReactComponent as SortArrowUpSvg } from '../../../images/icons/sort_up_arrow.svg';
import { ReactComponent as SortArrowDownSvg } from '../../../images/icons/sort_down_arrow.svg';

const arrowStyles = `
  width: 14px;
  height: 14px;
  margin-right: 5px;
  transition: 0.4s;
  path {
    fill: gray;
  }
`;
const SortStyled = styled(SortTwoArrowsSvg)`
  ${arrowStyles}
`;

const SortUpStyled = styled(SortArrowUpSvg)`
  ${arrowStyles}
`;

const SortDownStyled = styled(SortArrowDownSvg)`
  ${arrowStyles}
`;

const SortArrow = props => {
  const { name, orderingList } = props;

  const rotateArrow = () => {
    if (name) {
      if (orderingList.indexOf(name) >= 0) {
        return 'down';
      }
      if (orderingList.indexOf(`-${name}`) >= 0) {
        return 'up';
      }
    }
    return false;
  };

  if (rotateArrow() === 'down') {
    return <SortDownStyled />;
  }
  if (rotateArrow() === 'up') {
    return <SortUpStyled />;
  }
  return <SortStyled />;
};

export default SortArrow;

SortArrow.propTypes = {
  name: PropTypes.string,
  orderingList: PropTypes.array
};

SortArrow.defaultProps = {
  name: '',
  orderingList: []
};
