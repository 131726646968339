import React from 'react';
import styled from 'styled-components';
import PagesButtons from "./Button/PagesButtons";
import PropTypes from "prop-types";


const FooterContainerStyled = styled.div`
  height: 50px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

const ConteinerButtons = styled.div`
  width: fit-content;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const FooterPages = ( props ) => {

  const { pages, onClickPage, dataLoaded } = props;

  return (
      <FooterContainerStyled>
        <ConteinerButtons>
          <PagesButtons
              pages={pages}
              onClickPage={onClickPage}
              dataLoaded={dataLoaded}
          />
        </ConteinerButtons>
      </FooterContainerStyled>
  )
};
export default FooterPages


FooterPages.propTypes = {
  pages: PropTypes.any,
  onClickPage: PropTypes.any,
  tvProgramLoad: PropTypes.bool,
};

FooterPages.defaultProps = {
  pages: {},
  onClickPage: () => {},
  tvProgramLoad: true
};