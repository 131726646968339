import {Form} from "semantic-ui-react";
import SourceNameItem from "../common/Elements/Form/Items/SourceNameItem";
import InputItem from "../common/Elements/Form/Items/InputItem";
import React from "react";


const TvSourceNameForm = (props) => {
    const {
        sourceName,
        errorSourceName,
        OnChangeSourceName,

        searchValue,
        onChangeSearchValue,

        searchResults,
        searchLoad,
        onSelectSearch,

        folderName,
        errorFolderName,
        OnChangeFolderName,

        type,
        OnChangeType,

        serverIp,
        errorServerIp,
        OnChangeServerIp
    } = props;
    return(
        <>
            <Form.Field>
                <SourceNameItem
                    ItemTitle="Название источника"
                    ItemId="sourceName"
                    ItemValue={sourceName}
                    ValidationItem={errorSourceName}
                    onChangeFunction={OnChangeSourceName}

                    searchValue={searchValue}
                    onChangeSearchValue={onChangeSearchValue}
                    searchResults={searchResults}
                    searchLoad={searchLoad}
                    onSelectSearch={onSelectSearch}

                    disabled={true}
                />
            </Form.Field>

            <Form.Field>
                <InputItem
                    disabled={true}
                    ItemTitle="Папка"
                    ItemId="folderName"
                    ItemValue={folderName}
                    ValidationItem={errorFolderName}
                    onChangeFunction={OnChangeFolderName}
                />
            </Form.Field>

            <Form.Field>
                <InputItem
                    disabled={true}
                    ItemTitle="Тип Источника"
                    ItemId="type"
                    ItemValue={type}
                    ValidationItem={false}
                    onChangeFunction={OnChangeType}
                />
            </Form.Field>

            <Form.Field>
                <InputItem
                    disabled={true}
                    ItemTitle="Ip"
                    ItemId="serverIp"
                    ItemValue={serverIp}
                    ValidationItem={errorServerIp}
                    onChangeFunction={OnChangeServerIp}
                />
            </Form.Field>
        </>
    )
}

export default TvSourceNameForm;