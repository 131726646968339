import React, {useState, useEffect, useRef} from 'react';
import styled from "styled-components";

import ServiceAPI from "../../../services";

import DimmerLoader from "../../common/Elements/Loader/DimmerLoader";
import SaveButton from "../../common/Elements/Button/SaveButton";

import {AddTimeList, changeTimeList, DeleteTimeListItem} from "../../../helpersFunction/TimeListFunc";
import {changeState} from "../../../helpersFunction/ChnageState";
import {
    getWeekdaysOption,
    searchSite,
    selectSearchResult,
    validateTvRage
} from "../../../helpersFunction/TvRange";

import TvRangeForm from "./TvRangeForm";
import {throttle} from "lodash";


const CreateStyled = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 10px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 15px;
  padding-bottom: 15px;

  border-style: solid;
  border-width: 1px;
  border-color: rgb(235, 237, 244);

  padding-top: 60px;
`;

const SaveContainerStyled = styled.div`
  width: 85%;
  height: 45px;

  margin-top: 10px;

  padding-top: 5px;
  padding-bottom: 5px;

  margin-left: auto;
  margin-right: auto;
`;

const CreatePageTvRange = () => {

    const [tvRange, setTvRange] = useState({
        name: '',
        sourceId: '',
        sourceName: '',

        folderName: '',
        serverIp: '',
        type: '',

        timeList: [],
        processed: true,
        prime: false,
        enabled: true,
    });

    const [errors, setErrors] = useState({
        validForm: false,
        name: '',
        sourceName: '',
        timeList: '',
        weekdays: ''
    });

    const [create, setCreate] = useState(false);
    const [optionsWeekdays, setOptionsWeekday] = useState([{"key": "-", "value": "-", "text": "-"}]);

    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchLoad, setSearchLoad] = useState(false);
    const [selectSearch, setSelectSearch] = useState({});

    const service = new ServiceAPI();

    const ChangeTimeListItem = (key, value, keyField) => {
        changeTimeList(tvRange, setTvRange, keyField, key, value);
    };

    const SaveTvRange = () => {
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';

        setCreate(true);
        service.createTvRange(tvRange).then(res => {
            if (res.status === 201) {
                window.location.href = "/tv-range"

            } else {
                document.body.style.overflow = '';
                setCreate(false);
            }
        })
    };

    useEffect(() => {
        validateTvRage(tvRange, setErrors, errors)
    }, [tvRange]);

    useEffect(() => {
        getWeekdaysOption(setOptionsWeekday);
    }, []);

    const throttledSearch = useRef(throttle(
        (setSearchLoad, setSearchResults, searchValue) =>
            searchSite(setSearchLoad, setSearchResults, searchValue),
        700)
    );
    useEffect(() => {
        throttledSearch.current(setSearchLoad, setSearchResults, searchValue)
    }, [searchValue]);

    useEffect(() => {
        selectSearchResult(setSearchValue, setTvRange, tvRange, selectSearch);
    }, [selectSearch]);

    //VALIDATION SOURCE NAME
    // useEffect( ()=> {
    //   setTvRangeSelect(tvRange, selectSearch, setTvRange);
    // }, [tvRange.sourceName]);

    const OnClickSelectSearch = (e, data) => {
        setSelectSearch(data.result)
    };

    const DeleteTimeList = (id) => {
        DeleteTimeListItem(id, tvRange, setTvRange);
    };

    const DeleteAllTimeListItems = () => {
        setTvRange({...tvRange, timeList: []})
    };

    return (
        <CreateStyled>

            <DimmerLoader
                textOfLoader="Saving ..."
                active={create}
            />

            <TvRangeForm
                tvRange={tvRange}
                errorsTvRange={errors}

                OnChangeName={e => changeState("name", e.target.value, tvRange, setTvRange)}
                OnChangeType={e => changeState("type", e.target.value, tvRange, setTvRange)}
                OnChangeSourceName={e => changeState("sourceName", e.target.value, tvRange, setTvRange)}
                OnChangeFolderName={e => changeState("folderName", e.target.value, tvRange, setTvRange)}
                OnChangeServerIp={e => changeState("serverIp", e.target.value, tvRange, setTvRange)}
                OnChangeProcessed={(e, v) => changeState("processed", v.checked, tvRange, setTvRange)}
                OnChangePrime={(e, v) => changeState("prime", v.checked, tvRange, setTvRange)}
                OnChangeEnabled={(e, v) => changeState("enabled", v.checked, tvRange, setTvRange)}

                AddTimeListButton={() => AddTimeList(tvRange, setTvRange)}

                ChangeTimeListItem={ChangeTimeListItem}
                optionsWeekdays={optionsWeekdays}

                searchValue={searchValue}
                onChangeSearchValue={(e, {value}) => setSearchValue(value)}
                searchResults={searchResults}
                searchLoad={searchLoad}
                onSelectSearch={(e, data) => OnClickSelectSearch(e, data)}

                blockDelete={false}
                disabledDeleteButton={false}
                DeleteTimeListItem={DeleteTimeList}
                DeleteTimeList={DeleteAllTimeListItems}
            />

            <SaveContainerStyled>
                <SaveButton
                    disabled={!errors.validForm}
                    OnClick={SaveTvRange}
                    text={'Сохранить'}
                />
            </SaveContainerStyled>

        </CreateStyled>
    )
};

export default CreatePageTvRange;