import React, {useEffect, useState} from 'react';

import ServiceAPI from "../../../services";

import TabCreate from "../TabPage";

import {changeState} from "../../../helpersFunction/ChnageState";
import SelectedItems from "../../common/Elements/SelectedItems";
import {SelectAll} from "../../../helpersFunction/SelectAllId";
import TableHeaderTvRangeCut from "../../common/Elements/TvRangeCut/TvRangeCut";
import TvRangeListCut from "./TableList";
import FooterPages from "../../common/Elements/FooterPages";
import {setPageList} from "../../../helpersFunction/SetPage";
import {selectCheckBox} from "../../../helpersFunction/CheckBoxDelete";
import {OrderFieldsList} from "../../../helpersFunction/OrderFields";


const TvRangeCutListPage = (props) => {
  const optionsSearch = [
    { key: 'title', text: 'Заголовок', value: 'title' },
  ];

  const [orderingList, setOrderingList] = useState([]);
  const [selectedItems, setSelectedItems] = useState({selectedItems:[]});
  const [tvRanges, setTvRanges] = useState([]);
  const [tvRangesLoad, setTvRangesLoad] = useState(false);
  const [pages, setPages] = useState({
    prev: '',
    next: ''
  });
  const [search, setSearch] = useState({
    searchField: 'title',
    searchValue: ''
  });

  const service = new ServiceAPI();

  // Rewrite
  const getListTvRangeCut = ( params ) => {
    setTvRangesLoad(false);
    setTvRanges([]);

    service.getTvRageToCut(params ? params : null).then(resp => {
      if (resp.data) {
        setTvRanges(resp.data.results);
        setPageList(resp, pages, setPages);
        setTvRangesLoad(true);
      }
    });
  };
  // TODO fix 2 request
  //   useEffect(() => {
  //     getListTvRangeCut(null)
  //   }, []);

  const ChangePage = (params) => {
    getListTvRangeCut(params)
  };

  useEffect(() => {
    if (search.searchValue || orderingList) {
      let filter = {};

      if (orderingList && orderingList.length > 0) {
        filter.ordering = orderingList
      }

      if (search.searchValue) {
        filter[search.searchField] = search.searchValue
      }

      getListTvRangeCut(filter ? filter : null);
    }
  }, [orderingList, search]);

  const handleOrderingList = (name) => {
    OrderFieldsList(orderingList, setOrderingList, name)
  };

  const onClickCheckBox = (event) => {
    selectCheckBox(event, selectedItems, setSelectedItems)
  };

  const onClickDeleteList = () => {
    service.getTvRangeCutDeleteList(selectedItems).then(resp => {
      setSelectedItems({...selectedItems, selectedItems: []});
      getListTvRangeCut(null);
      setSearch({...search, searchValue:''})
    })
  };

  return (
      <>
        <TabCreate
            link={'/tv-range'}
            showButtonPage={true}
            showButton={true}
            showCreateButton={false}
            showListButton={false}
            pages={pages}
            onClickPage={ChangePage}
            dataLoaded={tvRangesLoad}
            optionsSearch={optionsSearch}
            OnChange={(e, {v})=> changeState("searchValue", e.target.value, search, setSearch)}
            SearchFieldChange={(e, v)=> changeState("searchField", v.value, search, setSearch)}
            onClickAllCheckBox={(e, v)=>SelectAll(tvRanges, selectedItems, setSelectedItems, "selectedItems", v.checked)}

            searchValue={search.searchValue}
        />

        <SelectedItems
            selectedItems={selectedItems.selectedItems}
            onClickCancel={()=> changeState("selectedItems", [], selectedItems, setSelectedItems)}
            onClickDeleteList={onClickDeleteList}
        />

        <TableHeaderTvRangeCut
            handleOrderingList={handleOrderingList}
            orderingList={orderingList}
        />

        <TvRangeListCut
            TvRanges={tvRanges}
            TvRangeLoad={tvRangesLoad}
            onClickCheckBox={onClickCheckBox}
            selectedItems={selectedItems.selectedItems}
        />

        <FooterPages
            pages={pages}
            onClickPage={ChangePage}
            dataLoaded={tvRangesLoad}
        />

      </>
  )
};

export default TvRangeCutListPage;