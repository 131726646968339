import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react'
import {SettingItemStyled} from "./styled";

export const ItemTitleStyled = styled.div`
  text-transform: uppercase;
  flex: 30%;
`;

export const ItemValueStyled = styled.div`
  flex:60%;
`;


const SelectItem = props => {
  const { ItemTitle, ItemValue, onChangeFunction, Options, error } = props;

  return (
      <SettingItemStyled>

        <ItemTitleStyled>
          <label>
            {ItemTitle} :
          </label>
        </ItemTitleStyled>

        <ItemValueStyled>
          <Form.Select
              error={error}
              options={Options}
              value={ItemValue}
              onChange={onChangeFunction}
          />

        </ItemValueStyled>

      </SettingItemStyled>
  );
};

export default SelectItem;

SelectItem.propTypes = {
  ItemTitle: PropTypes.string,
  ItemValue: PropTypes.any,
  Options: PropTypes.array,
  onChangeFunction: PropTypes.any,
};

SelectItem.default = {
  ItemTitle: '-',
  ItemValue: null,
  Options:[
    { value: "-", label: "-"},
  ],
  onChangeFunction: null
};
