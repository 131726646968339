import React from 'react';

import Header from "../containers/Header";
import TabCreate from "../containers/TabPage";
import CreatePage from "../containers/TvProgram/CreatePage";


const CreateTvProgramPage = () => {
  return (
      <>
        <Header />

        <TabCreate
            link={'/tv-program'}
            showButtonPage={false}
            showButton={false}
            showCreateButton={true}
            showListButton={true}
        />

        <CreatePage />
      </>
  )
};
export default CreateTvProgramPage;