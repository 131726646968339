import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import  NavItem  from './NavItem';

const NavStyled = styled.ul`
  display: flex;

  padding-left: 35px;
  width: 50%;
  & li {
    list-style: none;
    
    position: relative;
  }
  
`;

const NavStyledRight = styled.ul`
  display: flex;
  padding-right: 0;

  padding-left: 30%;
  width: 50%;
  & li {
    list-style: none;
    display:table-cell;
    text-align:center;
    position: relative;
  }

`;

const NavigationRight = styled.div`
  display: flex;
  border-bottom: 1px solid;
  margin-bottom: 20px;
  color: #cba3a8;
  background: #d2eaff24;
`;

const Navigation = () => {
  const role = JSON.parse(localStorage.getItem('user'))?.role?.toLowerCase();
  if (!role) {
    localStorage.removeItem('token');
    window.location.href = '/';
  }
  const isAdmin = (role === "admin");
  const isOperator = isAdmin || role === "operator";
  const isRiflingOperator = isAdmin || role === "rifling_operator";
  return (
    <>
      <NavigationRight>

        <NavStyled>
          <li>
            <NavItem link="/tv-articles" linkTitle="Расшифровка" access={isRiflingOperator || isOperator}/>
          </li>
          <li>
            <NavItem link="/tv-range-cut" linkTitle="Нарезка сюжетов" access={isRiflingOperator}/>
          </li>
          <li>
            <NavItem link="/youtube-article" linkTitle="YouTube" access={isOperator}/>
          </li>
        </NavStyled>

        <NavStyledRight>
          <li>
            <NavItem link="/billing" linkTitle="Биллинг" access={isAdmin}/>
          </li>
          <li>
          <NavItem link="/tv-program" linkTitle="Список программ" access={isAdmin}/>
          </li>
          <li>
            <NavItem link="/tv-range" linkTitle="ТВ Сетка" access={isAdmin}/>
          </li>

        </NavStyledRight>

    </NavigationRight>
    </>
  );
};

export default Navigation;

Navigation.propTypes = {
  showingTabs: PropTypes.arrayOf(PropTypes.string)
};

Navigation.defaultProps = {
  showingTabs: null
};
