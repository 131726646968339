import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const Column1 = styled.div`
  display: flex;
  align-items: center;
  flex: 10%;
  padding: 0 15px;
`;

const VerticalCenterStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.color ? props.color : '#4183c4;'}
`;

const TableItem = (props) => {
  const {name, color } = props;

  return (
      <Column1>
        <VerticalCenterStyled color={color}>
          {name}
        </VerticalCenterStyled>
      </Column1>
  )
};

export default TableItem;

TableItem.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
};

TableItem.defaultProps = {
  name: '',
  color: '#4183c4;',
};
