import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react'
import Search from "semantic-ui-react/dist/commonjs/modules/Search";
import {SettingItemStyled} from "./styled";

export const ItemTitleStyled = styled.div`
  text-transform: uppercase;
  flex: 25%;
`;

const ItemValueStyled = styled.div`
  flex: 35%;
  padding-right: 10px;
`;

const ItemSearchValueStyled = styled.div`
  flex: 25%;
`;

const SourceNameItem = props => {
  const {
    ItemTitle,
    ItemValue,
    onChangeFunction,
    ValidationItem,
    searchValue,
    onChangeSearchValue,
    searchResults,
    searchLoad,
    onSelectSearch,

    disabled
  } = props;

  return (
      <SettingItemStyled>

        <ItemTitleStyled>
          <label>
            {ItemTitle} :
          </label>
        </ItemTitleStyled>

        <ItemValueStyled>
          <Form.Input
              disabled={disabled}
              error={ValidationItem}
              placeholder={ItemTitle}
              onChange={onChangeFunction}
              value={ItemValue || ""}
          />
        </ItemValueStyled>
        <ItemSearchValueStyled>
          <Search
              loading={searchLoad}
              onResultSelect={onSelectSearch}
              onSearchChange={onChangeSearchValue}
              results={searchResults}
              value={searchValue}
          />
        </ItemSearchValueStyled>
      </SettingItemStyled>
  );
};

export default SourceNameItem;

SourceNameItem.propTypes = {
  ItemTitle: PropTypes.string,
  ItemValue: PropTypes.any,
  onChangeFunction: PropTypes.any,
  ValidationItem: PropTypes.string || PropTypes.bool,
  searchValue: "",

  searchResults: PropTypes.array,
  searchLoad: PropTypes.bool,
  onChangeSearchValue: PropTypes.any,
  onSelectSearch: PropTypes.any,
  disabled: PropTypes.bool,
};

SourceNameItem.default = {
  ItemTitle: '-',
  ItemValue: null,
  onChangeFunction: null,
  ValidationItem: false,
  searchValue: "",

  searchResults: [],
  searchLoad: false,
  onChangeSearchValue: ()=>{},
  onSelectSearch: () => {},

  disabled: true
};
