import React from 'react';
import Header from '../containers/Header';
import TvRangeCutListPage from "../containers/TvRangeCut/ListPage";



const TvRangeCut = () => {
  return (
      <>
        <Header />

        <TvRangeCutListPage />
      </>
  )
};
export default TvRangeCut;