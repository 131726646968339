import React from 'react';
import styled from 'styled-components';
import TableItem from "../Table/TableItem";
import TableIconItem from "../Table/ImgItem";

const TableRowStyled = styled.div`
  display: flex;
  padding: 8px 0;
  margin: 20px 0;
  font-size: 14px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border-radius: 8px;
    background: #b1b1b1;
    opacity: 0.7;
  }
`;

const TableRowYouTubeArticle = props => {
    const {
        YTArticleId,
        YTArticleUrl,
        YTArticleTitle,
        YTArticleHumanProcessed,
        YTArticleYoutubeParsed,
        YTArticleError
    } = props;
    //SET COLOR TO ITEM PROCESSED
    let color = '#4183c4;';
    if (YTArticleYoutubeParsed){
        color = '#84a504;'
    }
    if (YTArticleHumanProcessed){
        color = '#008a00;'
    }
    if (YTArticleError) {
        color = "#ff0039;"
    }
    return (
        <TableRowStyled>
            <TableItem
                name={YTArticleUrl}
                color={color}
            />
            <TableItem
                name={YTArticleTitle}
                color={color}
            />
            <TableIconItem
                value={YTArticleHumanProcessed}
            />
            <TableIconItem
                value={YTArticleYoutubeParsed}
            />
        </TableRowStyled>
    );
};

export default TableRowYouTubeArticle;


// TableRowTvRange.propTypes = {
//   reportUrl: PropTypes.string,
//   tvRangeName: PropTypes.string,
//   tvRangeSourceName: PropTypes.string,
//   tvRangeDate: PropTypes.string,
//   tvRangeProcessed: PropTypes.string,
//   tvRangePrime: PropTypes.string,
//   tvRangeEnabled: PropTypes.string,
// };
//
// TableRowTvRange.defaultProps = {
//   reportUrl: '',
//   tvRangeName: '',
//   tvRangeSourceName: '',
//   tvRangeDate: '',
//   tvRangeProcessed: '',
//   tvRangePrime: '',
//   tvRangeEnabled: '',
// };
