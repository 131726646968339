import React from 'react';

import { Map } from 'react-lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import TableRowTvArticle from "../../common/Elements/TvArticles/TableTvArticles";
import CheckBoxSelected from "../../common/Elements/Button/CheckBoxSelected";
import PageLoader from "../../common/Elements/Loader/PageLoader";


const ReportListContainerStyled = styled.div`
  overflow: auto;
`;

export const ContentWrapperStyled = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const ItemContainer = styled.div`
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

const TvArticlesList = ( props ) => {

  const { tvArticlesLoad, tvArticles, onClickCheckBox, selectedItems  } = props;

  return (
      <ContentWrapperStyled>
        <PageLoader
            Load={tvArticlesLoad}
        />
        <ReportListContainerStyled id="reportsListContainer">
          <Map
              collection={tvArticles}
              iteratee={value => {
                return (
                  <div key={value.id}>
                    <CheckBoxSelected
                        selectedItems={selectedItems.indexOf(value.id) !== -1}
                        onClickCheckBox={()=>onClickCheckBox(value.id)}
                    />
                    <ItemContainer>
                      <Link
                          key={value.id}
                          to={`/tv-articles/${value.id}`}
                          exact={"true"}>

                        <TableRowTvArticle
                            tvArticleId={value.id}
                            tvArticleTitle={value.title}
                            tvArticleSourceName={value.sourceName}
                            tvArticleDateStart={value.startTime}
                            tvArticleDateEnd={value.endTime}
                            tvArticleAudioProcessed={value.audioProcessed}
                            tvArticleHumanProcessed={value.humanProcessed}
                            tvArticlePrime={value.prime}
                        />

                      </Link>
                    </ItemContainer>
                  </div>
                );
              }}
          />
        </ReportListContainerStyled>
      </ContentWrapperStyled>
  )
};

export default TvArticlesList;