import { GetUrlFromDate } from "./UrlTime";

export function updateUrlFile(tvDate, tvObj, setPlayerState, playerState) {
  if(tvDate.dateViews){
    let serverUrl = tvObj.serverIp

    if (!serverUrl.includes('https://')){
      serverUrl = `https://${serverUrl}`
    }
    if (!serverUrl.includes('/video')){
      serverUrl = `${serverUrl}/video`
    }
    let url = GetUrlFromDate(serverUrl, tvObj.folderName, tvDate.dateViews, tvObj.type);
    setPlayerState({...playerState , url: url})
  }
}