import React from 'react';
import styled from 'styled-components';
import TableItem from "../Table/TableItem";
import TableIconItem from "../Table/ImgItem";

const TableRowStyled = styled.div`
  display: flex;
  padding: 8px 0;
  margin: 20px 0;
  font-size: 14px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border-radius: 8px;
    background: #b1b1b1;
    opacity: 0.7;
  }
`;

const TableRowTvRangeCut = props => {
    const {
        tvProgramId,
        tvRangeTitle,
        tvRangeStartTime,
        tvRangeEndTime,
        tvRangeDate,
        tvRangeWeekdays,
        tvRangeProcessed,
        tvRangePrime

    } = props;
    //SET COLOR TO ITEM PROCESSED
    let color = '#4183c4;';
    if (tvRangeProcessed) {
        color = '#008a00;'
    }

    return (
        <TableRowStyled>
            <TableItem
                name={tvRangeTitle}
                color={color}
            />
            <TableItem
                name={tvRangeStartTime}
                color={color}
            />
            <TableItem
                name={tvRangeEndTime}
                color={color}
            />
            <TableItem
                name={tvRangeDate}
                color={color}
            />
            <TableItem
                name={tvRangeWeekdays}
                color={color}
            />
            <TableIconItem
                value={tvRangeProcessed}
            />
            <TableIconItem
                value={tvRangePrime}
            />
        </TableRowStyled>
    );
};

export default TableRowTvRangeCut;


// TableRowTvRange.propTypes = {
//   reportUrl: PropTypes.string,
//   tvRangeName: PropTypes.string,
//   tvRangeSourceName: PropTypes.string,
//   tvRangeDate: PropTypes.string,
//   tvRangeProcessed: PropTypes.string,
//   tvRangePrime: PropTypes.string,
//   tvRangeEnabled: PropTypes.string,
// };
//
// TableRowTvRange.defaultProps = {
//   reportUrl: '',
//   tvRangeName: '',
//   tvRangeSourceName: '',
//   tvRangeDate: '',
//   tvRangeProcessed: '',
//   tvRangePrime: '',
//   tvRangeEnabled: '',
// };
