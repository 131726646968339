import React from 'react';
import {Button, Icon} from 'semantic-ui-react'
import PropTypes from "prop-types";


const UpdateButton = (props) => {
  const {OnClick, text, disabled} = props;
  return(
      <Button
          disabled={!disabled}
          onClick={OnClick}
          positive>
        <Icon name='save' />
        {text}
      </Button>
  )
};

export default UpdateButton;


UpdateButton.propTypes = {
  OnClick: PropTypes.any,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

UpdateButton.default = {
  OnClick: () => {},
  text: '',
  disabled: true,
};