import React from 'react';
import Header from '../containers/Header';
import TvArticlesListPage from "../containers/TvArticles/ListPage";

const TvArticles = () => {
  return (
      <>
        <Header />

        <TvArticlesListPage />
      </>
  )
};
export default TvArticles;