const initialState = {
  nextPage: "",
  prevPage: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_PARAMS': {
      return {
        ...state,
        [action.payload.type]: action.payload.value
      }
    }
  }
};
export default reducer;