import React from 'react';
import Header from '../containers/Header';
import YoutubeListPage from "../containers/YoutubeArticles/ListPage";


const YoutubeArticle = () => {
    return (
        <>
            <Header />

            <YoutubeListPage/>
        </>
    )
};
export default YoutubeArticle;